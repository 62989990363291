import { AxiosResponse } from "axios";

import { api } from "@app/api/api";
import { RequestParams } from "@app/types/api.types";

import { SettingsEndpoints } from "../constants/settings.endpoints";

export const getSettingsListApi = (
  params?: RequestParams
): Promise<AxiosResponse> => {
  return api.get(SettingsEndpoints.GET_SETTINGS_LIST, { params });
};

export const updateSettingApi = ({
  id,
  body,
}: {
  id: number;
  body: {
    value: string;
  };
}): Promise<AxiosResponse> => {
  return api.patch(
    SettingsEndpoints.UPDATE_SETTING.replace(":id", String(id)),
    body
  );
};

export const settingsApi = { getSettingsListApi, updateSettingApi };
