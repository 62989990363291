import { Shop } from "@app/features/packs/packs";

export const getShopsName = (shops: Shop[] | null) => {
  if (!shops || !shops.length) {
    return "";
  }

  return shops
    .reduce((result, shop) => {
      result.push(shop.name);
      return result;
    }, [] as string[])
    .join(", ");
};
