import { memo, useEffect, useMemo, useState } from "react";

import { Input, Space, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { LIST_CODE_SCREEN } from "@app/constants/constants";
import { isNotGranted } from "@app/helpers/permisstion.helper";
import { getMessageErrors } from "@app/helpers/util.helper";
import useGranted from "@app/hooks/useGranted/useGranted";
import { useAppDispatch } from "@app/redux/store";

import UserPreviewCard from "../../components/UserPreviewCard/UserPreviewCard";
import {
  UserDetail,
  UserPathEnum,
  addPoint,
  resetPoint,
  getUserDetail,
  deleteUser,
  generateUserAddress,
} from "../../users";

const PreviewUserScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useGranted({ screenCode: LIST_CODE_SCREEN.USER_DETAIL });

  const [userData, setUserData] = useState<UserDetail>();
  const [triggerReload, setTriggerReload] = useState<boolean>(false);

  const { userId } = useParams<{ userId: string }>();
  const [form] = useForm();

  const userInfo = useMemo(() => generateUserAddress(userData), [userData]);

  const handleSubmitAddPoint = async () => {
    const point = form.getFieldValue("point");
    if (!userId) {
      return false;
    }

    return dispatch(addPoint({ userId, point }))
      .then(() => {
        form.resetFields();
        setTriggerReload(!triggerReload);
      })
      .catch(err => {
        getMessageErrors(err);
      });
  };

  const handleDeletePoint = async () => {
    if (!userId) {
      return false;
    }
    return dispatch(resetPoint(userId))
      .then(() => {
        setTriggerReload(!triggerReload);
      })
      .catch(err => {
        getMessageErrors(err);
      });
  };

  const handleDeleteUser = async () => {
    if (!userId || userInfo?.total_point) {
      return false;
    }

    return dispatch(deleteUser(userId))
      .then(() => {
        navigate(UserPathEnum.LIST_USER);
      })
      .catch(err => {
        getMessageErrors(err);
      });
  };

  useEffect(() => {
    if (userId) {
      dispatch(getUserDetail(userId))
        .unwrap()
        .then(res => {
          setUserData(res);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userId, triggerReload]);

  return (
    <>
      {userInfo.id ? (
        <div className="my-10">
          <Typography.Title level={3}>ユーザー詳細</Typography.Title>
          <div className="mw-600 mx-auto">
            <div className="flex-space-between flex-wrap">
              <div className="d-flex">
                <Typography className="font-weight-medium">
                  所持ポイント：
                </Typography>
                <Typography.Paragraph strong>
                  {userInfo.total_point}Pt
                </Typography.Paragraph>
              </div>
              <Form form={form}>
                <Item name="point">
                  <Input type="number" />
                </Item>
                <Item shouldUpdate>
                  {({ getFieldValue }) => (
                    <Button
                      className="full-width"
                      type="primary"
                      disabled={
                        !getFieldValue("point") ||
                        isNotGranted(LIST_CODE_SCREEN.USER_ADD_POINT)
                      }
                      htmlType="submit"
                      isShowConfirm
                      onSubmit={() => handleSubmitAddPoint()}
                      title="ユーザーにPOINTを付与します。よろしいですか？"
                    >
                      POINT付与
                    </Button>
                  )}
                </Item>
              </Form>
            </div>

            <Form>
              <Item
                name="username"
                label="ユーザー名"
                initialValue={userInfo.username}
              >
                <Input size="large" disabled />
              </Item>

              <Item
                name="email"
                label="メールアドレス"
                initialValue={userInfo.email}
              >
                <Input size="large" disabled />
              </Item>
            </Form>

            <div className="mt-5 flex-justify-end flex-wrap gap-10">
              <Button
                disabled={isNotGranted(LIST_CODE_SCREEN.USER_DELETE_POINT)}
                type="primary"
                onClick={handleDeletePoint}
              >
                POINT削除
              </Button>
              <Button
                disabled={
                  !!userInfo.total_point ||
                  isNotGranted(LIST_CODE_SCREEN.USER_DELETE)
                }
                type="primary"
                onClick={handleDeleteUser}
              >
                ユーザーを削除
              </Button>
            </div>

            <div className="mt-5 flex-wrap">
              <Typography className="font-weight-medium">送り先住所</Typography>
            </div>

            <div className="mt-5 d-flex gap-30">
              <Typography.Text className="no-wrap font-weight-medium">
                メイン
              </Typography.Text>
              {userInfo.addressesDefault.addressDetail && (
                <UserPreviewCard
                  direction="vertical"
                  name={`${userInfo.addressesDefault.firstname}
                  ${userInfo.addressesDefault.lastname}`}
                  zipCode={userInfo.addressesDefault.zipcode}
                  address={userInfo.addressesDefault.addressDetail}
                  tel={userInfo.addressesDefault.tel}
                />
              )}
            </div>

            <div className="mt-5 d-flex gap-45">
              <Typography.Text className="no-wrap font-weight-medium">
                サブ
              </Typography.Text>
              {userInfo?.addresses && (
                <Space direction="vertical" size="middle">
                  {userInfo?.addresses?.map(address => (
                    <UserPreviewCard
                      direction="vertical"
                      key={address.id}
                      name={address.userName}
                      zipCode={address.zipcode}
                      address={address.addressDetail}
                      tel={address.tel}
                    />
                  ))}
                </Space>
              )}
            </div>

            <div className="mt-15 flex-center">
              <Button
                className="full-width mw-200"
                onClick={() => navigate(UserPathEnum.LIST_USER)}
              >
                キャンセル
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <LoadingSpinner isFitContent />
      )}
    </>
  );
};

export default memo(PreviewUserScreen);
