import { createSlice } from "@reduxjs/toolkit";

import { createApiAsyncThunk } from "@app/redux/api.thunk";

import {
  ADMINS_FEATURE_KEY,
  AdminAccountCompleteParams,
  AdminAccountTemporaryParams,
  AdminsState,
  ListAdminRequestParams,
  UpdateAdminDetail,
} from "../admins";
import adminsApi from "../api/admins.api";

const initialState: AdminsState = {
  adminsList: null,
  adminDetail: null,
};

export const getAdminList = createApiAsyncThunk(
  `${ADMINS_FEATURE_KEY}/getAdminList`,
  (params?: ListAdminRequestParams) => {
    return adminsApi.getAdminListApi(params);
  }
);

export const resendVerificationEmail = createApiAsyncThunk(
  `${ADMINS_FEATURE_KEY}/resendVerificationEmail`,
  async (email: string) => adminsApi.resendVerificationEmailApi(email)
);

export const deleteAdminAccount = createApiAsyncThunk(
  `${ADMINS_FEATURE_KEY}/deleteAdminAccount`,
  async (adminId: string) => adminsApi.deleteAdminAccountApi(adminId)
);

export const createAdminAccountTemporary = createApiAsyncThunk(
  `${ADMINS_FEATURE_KEY}/createAdminAccountTemporary`,
  async (data: AdminAccountTemporaryParams) =>
    adminsApi.createAdminAccountTemporaryApi(data)
);

export const checkConfirmationTokenAdmin = createApiAsyncThunk(
  `${ADMINS_FEATURE_KEY}/checkConfirmationTokenAdmin`,
  async (confirmationToken: string) =>
    adminsApi.checkConfirmationTokenAdminApi(confirmationToken)
);

export const createAdminAccountComplete = createApiAsyncThunk(
  `${ADMINS_FEATURE_KEY}/createAdminAccountComplete`,
  async (data: AdminAccountCompleteParams) =>
    adminsApi.createAdminAccountCompleteApi(data)
);

export const verifyAdmin = createApiAsyncThunk(
  `${ADMINS_FEATURE_KEY}/verifyAdmin`,
  async (confirmationToken: string) =>
    adminsApi.verifyAdminApi(confirmationToken)
);

export const getAdminDetail = createApiAsyncThunk(
  `${ADMINS_FEATURE_KEY}/getAdminDetail`,
  async (detailId: string) => {
    return adminsApi.getAdminDetailApi(detailId);
  }
);

export const updateAdmin = createApiAsyncThunk(
  `${ADMINS_FEATURE_KEY}/updateAdminDetail`,
  ({ detailId, data }: { detailId: string; data: UpdateAdminDetail }) => {
    return adminsApi.updateAdminApi({ detailId, data });
  }
);

const adminsSlice = createSlice({
  name: ADMINS_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAdminList.fulfilled, (state, action) => {
      state.adminsList = action.payload;
    });
    builder.addCase(getAdminList.rejected, state => {
      state.adminsList = null;
    });
    builder.addCase(getAdminDetail.pending, state => {
      state.adminDetail = null;
    });
    builder.addCase(getAdminDetail.fulfilled, (state, action) => {
      state.adminDetail = action.payload;
    });
    builder.addCase(getAdminDetail.rejected, state => {
      state.adminDetail = null;
    });
  },
});

export const adminsReducer = adminsSlice.reducer;
