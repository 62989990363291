import { Typography } from "antd";
import { useLocation } from "react-router-dom";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { LIST_CODE_SCREEN } from "@app/constants/constants";
import useGranted from "@app/hooks/useGranted/useGranted";
import { useAppDispatch } from "@app/redux/store";

import { editBanner } from "../../banners";
import FormDataBanner from "../../components/FormDataBanner/FormDataBanner";

const EditBannerScreen = () => {
  const dispatch = useAppDispatch();
  const bannerDetail = useLocation().state.banner;
  useGranted({ screenCode: LIST_CODE_SCREEN.BANNER_EDIT });

  const handleUpdateBanner = (data: FormData) => {
    return dispatch(
      editBanner({ bannerId: String(bannerDetail.id), data })
    ).unwrap();
  };

  return (
    <>
      {bannerDetail ? (
        <>
          <Typography.Title level={3}>バナー編集</Typography.Title>
          <FormDataBanner
            bannerDetail={bannerDetail}
            onSubmit={data => handleUpdateBanner(data)}
          />
        </>
      ) : (
        <LoadingSpinner isFitContent />
      )}
    </>
  );
};

export default EditBannerScreen;
