import { memo, useEffect, useMemo, useState } from "react";

import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Table, Typography } from "antd";
import { get } from "lodash";
import { serialize } from "object-to-formdata";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import Row from "@app/components/atoms/CustomRow/CustomRow";
import { LIST_CODE_SCREEN } from "@app/constants/constants";
import { isNotGranted } from "@app/helpers/permisstion.helper";
import { getMessageErrors, handleSendMailto } from "@app/helpers/util.helper";
import useGranted from "@app/hooks/useGranted/useGranted";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import { ROLES_TYPE_LIST } from "../../../roles/constants/roles.constants";
import {
  SHOP_LIST_COLUMN,
  SHOP_STATUS,
  ShopDetail,
  ShopPathsEnum,
  deleteShop,
  getShopList,
  updateShop,
  updateShopDisplayOrder,
  updateShopList,
  updateStatus,
} from "../../shops";

const ShopListScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentUser } = useAppSelector((state: RootState) => state.auth);

  useGranted({ screenCode: LIST_CODE_SCREEN.SHOP_LIST });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { shopList } = useSelector((state: RootState) => state.shops);
  const [triggerReload, setTriggerReload] = useState<boolean>(false);

  const isNotAdminShopType =
    currentUser?.role.type !== ROLES_TYPE_LIST.ADMIN_SHOP;

  const handleDeleteShop = (shopId: string) => {
    return dispatch(deleteShop(shopId))
      .unwrap()
      .then(() => setTriggerReload(!triggerReload))
      .catch(err => {
        getMessageErrors(err);
      });
  };

  const handleHiddenShop = async (item: ShopDetail) => {
    const data = serialize({
      ...item,
      image_url: undefined,
      is_display: 0,
    });
    await dispatch(
      updateShop({
        shopId: String(item.id),
        data,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(updateStatus({ ...item, is_display: false }));
      })
      .catch(err => getMessageErrors(err));
  };

  const handleRedirectToEditPage = (shopId: string) => {
    return navigate(ShopPathsEnum.EDIT_SHOP.replace(":shopId", shopId));
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over || active.id === over?.id) return;

    const activeIndex =
      shopList?.shops?.findIndex(i => i.id === active.id) ?? 0;
    const overIndex = shopList?.shops?.findIndex(i => i.id === over?.id) ?? 0;
    const shopListUpdate = arrayMove(
      [...(shopList?.shops ?? [])],
      activeIndex,
      overIndex
    );

    const activeOder = get(shopListUpdate, `${overIndex - 1}.display_order`, 0);

    dispatch(
      updateShopList({
        ...shopList,
        shops: shopListUpdate,
      })
    );

    setIsLoading(true);
    dispatch(
      updateShopDisplayOrder({
        shopId: String(active.id),
        previous_display_order: activeOder,
      })
    )
      .unwrap()
      .catch(err => {
        getMessageErrors(err);
      })
      .finally(() => {
        setIsLoading(false);
        setTriggerReload(!triggerReload);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(getShopList({}))
      .unwrap()
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, triggerReload, navigate]);

  const shopDataSource = useMemo(() => {
    return shopList?.shops.map(item => ({
      ...item,
      status: item.is_display ? SHOP_STATUS.SHOW : SHOP_STATUS.HIDE,
      action: (
        <div className="flex-align-center">
          <Button
            disabled={isNotGranted(LIST_CODE_SCREEN.SHOP_BUTTON_SEND_MAIL)}
            onClick={() => handleSendMailto(item.email)}
          >
            メール送信
          </Button>
          <Button
            className="ml-3"
            disabled={isNotGranted(LIST_CODE_SCREEN.SHOP_EDIT)}
            type="primary"
            onClick={() => handleRedirectToEditPage(String(item.id))}
          >
            編集
          </Button>
          <Button
            className="ml-3"
            type="primary"
            disabled={isNotGranted(LIST_CODE_SCREEN.SHOP_DELETE)}
            danger
            isShowConfirm
            onSubmit={() => handleDeleteShop(String(item.id))}
          >
            削除
          </Button>
          {isNotAdminShopType && (
            <Button
              className="ml-3"
              type="primary"
              title="企業情報を非表示にしますが、よろしいですか。"
              disabled={
                isNotGranted(LIST_CODE_SCREEN.SHOP_EDIT) || !item.is_display
              }
              danger
              isShowConfirm
              onSubmit={() => handleHiddenShop(item)}
            >
              企業情報非表示
            </Button>
          )}
        </div>
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopList?.shops]);

  return (
    <div>
      <Typography.Title level={3}>企業一覧</Typography.Title>
      <Button
        className="mt-4"
        type="primary"
        width="300px"
        disabled={isNotGranted(LIST_CODE_SCREEN.SHOP_CREATE)}
        to={ShopPathsEnum.CREATE_SHOP}
      >
        企業作成
      </Button>

      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={shopDataSource ? shopDataSource?.map(i => i.id) : []}
          strategy={verticalListSortingStrategy}
          disabled={isNotGranted(LIST_CODE_SCREEN.SHOP_SORT)}
        >
          <Table
            className="py-10"
            components={{
              body: {
                row: Row,
              },
            }}
            bordered
            rowKey="id"
            loading={isLoading}
            dataSource={shopDataSource}
            columns={[
              {
                key: "sort",
                fixed: "left",
                width: "50px",
              },
              ...SHOP_LIST_COLUMN,
              {
                title: "操作",
                dataIndex: "action",
                width: isNotAdminShopType ? 450 : 300,
              },
            ]}
            pagination={false}
            scroll={{ x: 1300 }}
          />
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default memo(ShopListScreen);
