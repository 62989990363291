import { api } from "@app/api/api";

import { DEFAULT_PER_PAGE } from "../../../constants/constants";
import {
  CardMasterRequest,
  CardMastersEndpointsEnum,
  ListCardRaresRequest,
} from "../card-masters";

const getCardRaresListApi = (params: ListCardRaresRequest) => {
  const url = CardMastersEndpointsEnum.CARD_RARE;
  return api.get(url, { params: { ...params, limit: DEFAULT_PER_PAGE } });
};

const deleteCardRaresApi = (id: string) => {
  return api.delete(CardMastersEndpointsEnum.EDIT_CARD_RARE.replace(":id", id));
};

const editCardRaresApi = (id: string, data: FormData) => {
  const url = CardMastersEndpointsEnum.EDIT_CARD_RARE.replace(
    ":id",
    String(id)
  );
  return api.post(url, data, {
    params: {
      _method: "PATCH",
    },
    transformRequest: () => {
      return data;
    },
  });
};

const createCardRaresApi = (data: FormData) => {
  const url = CardMastersEndpointsEnum.CARD_RARE;
  return api.post(url, data, {
    transformRequest: () => {
      return data;
    },
  });
};

const getListCardMastersApi = (params: CardMasterRequest) => {
  const url = CardMastersEndpointsEnum.CARD_MASTERS;
  return api.get(url, { params });
};
const cardRaresApi = {
  createCardRaresApi,
  deleteCardRaresApi,
  editCardRaresApi,
  getCardRaresListApi,
  getListCardMastersApi,
};

export default cardRaresApi;
