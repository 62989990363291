import { useEffect, useMemo, useState } from "react";

import { Col, Input, Row, Table, Typography } from "antd";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import PaginationCustom from "@app/components/atoms/PaginationCustom/PaginationCustom";
import { LIST_CODE_SCREEN } from "@app/constants/constants";
import { UserPathEnum } from "@app/features/users/users";
import { isNotGranted } from "@app/helpers/permisstion.helper";
import { getMessageErrors } from "@app/helpers/util.helper";
import useGranted from "@app/hooks/useGranted/useGranted";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import { getCardList, updateCardStatus } from "../../cards";
import {
  CARD_STATUS,
  CARD_STATUS_JA,
  COLUMNS_TABLE_CARDS,
} from "../../constants/cards.constants";
import { getCardName, getShipAddress } from "../../helpers/card.helper";

const CardListUserScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [triggerReload, setTriggerReload] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const { cardList } = useSelector((state: RootState) => state.cards);
  const page = Number(searchParams.get("page") ?? 1);
  const { userId } = useParams<{ userId: string }>();

  useGranted({ screenCode: LIST_CODE_SCREEN.CARD_OF_USER_LIST });

  useEffect(() => {
    setIsLoading(true);

    userId &&
      dispatch(
        getCardList({
          user_id: userId,
          tel: searchParams.get("tel") ?? undefined,
          statuses: [
            CARD_STATUS.WAIT_FOR_SEND,
            CARD_STATUS.SENT,
            CARD_STATUS.SENDING,
            CARD_STATUS.SOLD,
          ],
          page,
          use_page: "true",
        })
      )
        .unwrap()
        .catch(err => getMessageErrors(err))
        .finally(() => {
          setIsLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchParams, form, page, triggerReload]);

  const handleUpdateCardStatus = (itemId: string) => {
    return dispatch(updateCardStatus({ id: itemId }))
      .unwrap()
      .then(() => setTriggerReload(!triggerReload))
      .catch(err => {
        getMessageErrors(err);
      });
  };

  const cardListConvert = useMemo(() => {
    return cardList?.cards.map(item => ({
      key: item.id,
      id: item.id,
      updatedAt: item.updated_at,
      status: CARD_STATUS_JA[item.status],
      shopName: item.shop?.name,
      shopMail: item.shop?.email,
      shopTel: item.shop?.tel,
      cardName: item.rare ? getCardName(item) : "",
      packName: item?.pack?.title,
      shipAddress:
        item.delivery_history && getShipAddress(item.delivery_history),
      phoneShip: item.delivery_history?.tel,
      action: (
        <Button
          disabled={
            item.status !== CARD_STATUS.WAIT_FOR_SEND ||
            isNotGranted(LIST_CODE_SCREEN.CARD_COMPLETE_SENT)
          }
          type="primary"
          onSubmit={() => handleUpdateCardStatus(`${item.id}`)}
        >
          ステータス変更
        </Button>
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardList]);

  const handleSearch = (searchInput: string) => {
    if (searchInput.trim() === "") {
      searchParams.delete("tel");
    } else {
      searchParams.set("tel", searchInput.trim());
    }
    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  return (
    <div>
      <Typography.Title level={3}>ユーザの獲得カード一覧</Typography.Title>
      <Button
        width="200px"
        className="my-4"
        onClick={() => navigate(UserPathEnum.LIST_USER)}
      >
        戻る
      </Button>
      <Form
        className="mb-10"
        form={form}
        onFinish={() => handleSearch(form.getFieldValue("tel"))}
      >
        <Row>
          <Col span={8}>
            <Item
              initialValue={searchParams.get("tel")}
              label="発送の電話番号"
              name="tel"
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Button
              htmlType="submit"
              type="primary"
              width="100%"
              disabled={isLoading}
            >
              検索
            </Button>
          </Col>
        </Row>
      </Form>

      <Table
        className="mt-5"
        bordered
        pagination={false}
        loading={isLoading}
        dataSource={cardListConvert}
        columns={COLUMNS_TABLE_CARDS}
        scroll={{ x: 1300 }}
      />
      <div className="mt-8 pb-5 text-center">
        <PaginationCustom
          current={page}
          total={cardList?.page_info?.total_count}
        />
      </div>
    </div>
  );
};

export default CardListUserScreen;
