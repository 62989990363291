import { AxiosResponse } from "axios";

import { api } from "@app/api/api";
import { RequestParams } from "@app/types/api.types";

import { LabelsEndpointsEnum } from "../constants/labels.endpoints";
import { UpdateLabelDisplayOrderRequestBody } from "../labels";

const labelListApi = (params?: RequestParams) => {
  const url = LabelsEndpointsEnum.LIST_LABEL;
  return api.get(url, { params });
};

const deleteLabelApi = ({ id }: { id: number }) => {
  const url = LabelsEndpointsEnum.EDIT_LABEL.replace(":id", `${id}`);
  return api.delete(url);
};

const createLabelApi = (data: FormData) => {
  const url = LabelsEndpointsEnum.LIST_LABEL;
  return api.post(url, data, {
    transformRequest: () => {
      return data;
    },
  });
};

const editLabelApi = ({
  labelId,
  data,
}: {
  labelId: string;
  data: FormData;
}) => {
  const url = LabelsEndpointsEnum.EDIT_LABEL;
  return api.post(url.replace(":id", labelId), data, {
    params: {
      _method: "PATCH",
    },
    transformRequest: () => {
      return data;
    },
  });
};

const updateLabelDisplayOrderApi = ({
  labelId,
  previous_display_order,
}: UpdateLabelDisplayOrderRequestBody): Promise<AxiosResponse> => {
  const url = LabelsEndpointsEnum.LABEL_UPDATE_DISPLAY_ORDER.replace(
    ":id",
    labelId
  );
  return api.patch(url, { previous_display_order });
};

const labelApi = {
  labelListApi,
  deleteLabelApi,
  createLabelApi,
  editLabelApi,
  updateLabelDisplayOrderApi,
};

export default labelApi;
