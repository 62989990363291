import { AxiosResponse } from "axios";

import { api } from "@app/api/api";
import { DEFAULT_PER_PAGE } from "@app/constants/constants";

import { PaymentHistoriesEndpoints } from "../constants/payment-histories.endpoints";
import {
  BodyParams,
  PaymentHistoriesRequestParams,
} from "../types/payment-histories.types";

export const getPaymentHistoriesListApi = (
  params?: PaymentHistoriesRequestParams
): Promise<AxiosResponse> => {
  return api.get(PaymentHistoriesEndpoints.GET_PAYMENT_HISTORIES_LIST, {
    params: { ...params, limit: DEFAULT_PER_PAGE },
  });
};

export const updatePaymentHistoriesApi = ({
  body,
}: {
  body: BodyParams;
}): Promise<AxiosResponse> => {
  return api.post(
    PaymentHistoriesEndpoints.CONFIRM_PAYMENT_HISTORIES_PAYMENT,
    body
  );
};

export const paymentHistoriesApi = {
  getPaymentHistoriesListApi,
  updatePaymentHistoriesApi,
};
