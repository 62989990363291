import { getColumnTableConvertNullable } from "@app/helpers/util.helper";

export const COLUMNS_TABLE_USERS = getColumnTableConvertNullable([
  {
    title: "ユーザー名",
    dataIndex: "username",
  },
  {
    title: "メールアドレス",
    dataIndex: "email",
  },
  {
    title: "電話番号",
    dataIndex: "tel",
    width: 140,
  },
  {
    title: "所持POINT",
    dataIndex: "total_point",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: 450,
  },
]);
