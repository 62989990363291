import { memo, ReactNode, useEffect, useRef, useState } from "react";

import { UserOutlined } from "@ant-design/icons";
import { useMount } from "ahooks";
import { Avatar, Popover, Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Layout, { Content, Header } from "antd/lib/layout/layout";
import cx from "classnames";
import { isBoolean } from "lodash";
import { Link, useNavigate } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import { AuthPathsEnum, getCurrentUser, logout } from "@app/features/auth/auth";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import styles from "./DefaultLayout.module.scss";
import SiderLayout from "./SiderLayout/SiderLayout";

type DefaultLayoutProps = {
  children: ReactNode;
};

const DefaultLayout = ({ children }: DefaultLayoutProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const { xl } = useBreakpoint();

  useEffect(() => {
    if (isBoolean(xl)) {
      setCollapsed(!xl);
    }
  }, [xl]);

  const containerRef = useRef(null);

  useMount(() => {
    dispatch(getCurrentUser({}));
  });

  const handleLogout = () => {
    return dispatch(logout({}))
      .unwrap()
      .then(() => navigate(AuthPathsEnum.LOGIN));
  };

  const { currentUser } = useAppSelector((state: RootState) => state.auth);

  return (
    <Layout ref={containerRef} className={cx(styles.root)}>
      <Header className={cx(styles.header, "flex-align-center")}>
        <Typography.Text ellipsis className="text-white mw-400 mr-4">
          {currentUser?.username}
        </Typography.Text>
        {containerRef?.current ? (
          <Popover
            placement="bottomRight"
            title=""
            getPopupContainer={() =>
              containerRef.current as unknown as HTMLElement
            }
            content={
              <div className="text-center pt-2">
                <Link
                  className="font-15 text-link"
                  onClick={() => setVisible(false)}
                  to={AuthPathsEnum.MY_PAGE}
                >
                  マイページ
                </Link>
                <br />
                <Button
                  onSubmit={handleLogout}
                  danger
                  type="primary"
                  className="font-14 mt-4"
                >
                  ログアウト
                </Button>
              </div>
            }
            trigger="click"
            overlayStyle={{
              width: 200,
            }}
            open={visible}
            onOpenChange={setVisible}
          >
            <Avatar
              size={45}
              rootClassName={cx(styles.avatar, styles.avatarMain)}
              icon={<UserOutlined />}
              className="cursor-pointer"
            />
          </Popover>
        ) : (
          <Avatar
            size={45}
            rootClassName={cx(styles.avatar)}
            icon={<UserOutlined />}
          />
        )}
      </Header>
      <Layout hasSider>
        <SiderLayout collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content
          className={cx(styles.content, {
            [styles.contentCollapsed]: collapsed,
          })}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default memo(DefaultLayout);
