import { createSlice } from "@reduxjs/toolkit";

import { createApiAsyncThunk } from "@app/redux/api.thunk";

import { paymentHistoriesApi } from "../api/payment-histories.api";
import { PAYMENT_HISTORIES_FEATURE_KEY } from "../constants/payment-histories.keys";
import { StatusConfirmPayment } from "../payment-histories";
import {
  PaymentHistoriesState,
  PaymentHistoriesRequestParams,
} from "../types/payment-histories.types";

const initialState: PaymentHistoriesState = {
  paymentHistoriesList: null,
};

export const getPaymentHistoriesList = createApiAsyncThunk(
  `${PAYMENT_HISTORIES_FEATURE_KEY}/getPaymentHistoriesList`,
  (params?: PaymentHistoriesRequestParams) => {
    return paymentHistoriesApi.getPaymentHistoriesListApi(params);
  }
);

export const updatePaymentHistories = createApiAsyncThunk(
  `${PAYMENT_HISTORIES_FEATURE_KEY}/updatePaymentHistories`,
  (params: {
    body: { payment_history_id: number; action: StatusConfirmPayment };
  }) => {
    return paymentHistoriesApi.updatePaymentHistoriesApi(params);
  }
);

const paymentHistoriesSlice = createSlice({
  name: PAYMENT_HISTORIES_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getPaymentHistoriesList.fulfilled, (state, action) => {
      state.paymentHistoriesList = action.payload;
    });
    builder.addCase(getPaymentHistoriesList.rejected, state => {
      state.paymentHistoriesList = null;
    });
  },
});

export const paymentHistoriesReducer = paymentHistoriesSlice.reducer;
