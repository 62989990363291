import { useEffect, useMemo, useState } from "react";

import { Table, Typography } from "antd";
import { useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import PaginationCustom from "@app/components/atoms/PaginationCustom/PaginationCustom";
import { LIST_CODE_SCREEN } from "@app/constants/constants";
import { isNotGranted } from "@app/helpers/permisstion.helper";
import { getMessageErrors } from "@app/helpers/util.helper";
import useGranted from "@app/hooks/useGranted/useGranted";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import { BannersPathsEnum, deleteBanner, getBannerList } from "../../banners";
import {
  COLUMNS_TABLE_BANNERS,
  DISPLAY_STATUS,
} from "../../constants/banners.constants";

const BannerListScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [triggerReload, setTriggerReload] = useState<boolean>(false);
  useGranted({ screenCode: LIST_CODE_SCREEN.BANNER_LIST });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get("page") ?? 1);
  const { bannerList } = useSelector((state: RootState) => state.banners);

  useEffect(() => {
    setIsLoading(true);

    dispatch(
      getBannerList({
        page,
        use_page: "true",
      })
    )
      .unwrap()
      .catch(err => getMessageErrors(err))
      .finally(() => setIsLoading(false));
  }, [dispatch, page, triggerReload]);

  const handleDelete = (id: number) => {
    return dispatch(deleteBanner({ id }))
      .unwrap()
      .then(() => setTriggerReload(!triggerReload))
      .catch(err => {
        getMessageErrors(err);
      });
  };

  const bannerListConvert = useMemo(() => {
    return bannerList?.banners.map(item => ({
      ...item,
      is_display: DISPLAY_STATUS[item.is_display],
      action: (
        <div className="flex-space-between">
          <Button
            type="primary"
            disabled={isNotGranted(LIST_CODE_SCREEN.BANNER_EDIT)}
            onClick={() =>
              navigate(
                BannersPathsEnum.EDIT_BANNER.replace(":id", `${item.id}`),
                {
                  state: {
                    banner: item,
                  },
                }
              )
            }
          >
            編集
          </Button>
          <Button
            danger
            type="primary"
            isShowConfirm
            disabled={isNotGranted(LIST_CODE_SCREEN.BANNER_DELETE)}
            onSubmit={() => handleDelete(item.id)}
          >
            削除
          </Button>
        </div>
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerList]);

  return (
    <div>
      <Typography.Title level={3}>バナー一覧画面</Typography.Title>

      <Button
        type="primary"
        className="mt-5"
        width="300px"
        disabled={isNotGranted(LIST_CODE_SCREEN.BANNER_CREATE)}
        onClick={() => navigate(BannersPathsEnum.CREATE_BANNER)}
      >
        新規追加
      </Button>

      <Table
        className="mt-5 mb-8"
        bordered
        pagination={false}
        columns={COLUMNS_TABLE_BANNERS}
        dataSource={bannerListConvert}
        loading={isLoading}
      />

      <PaginationCustom
        current={page}
        total={bannerList?.page_info?.total_count}
      />
    </div>
  );
};

export default BannerListScreen;
