import { api } from "@app/api/api";
import { DEFAULT_PER_PAGE } from "@app/constants/constants";
import { RequestParams } from "@app/types/api.types";

import {
  CardImagesEndpointsEnum,
  DeleteCardImageRequestParams,
  GetCardImageListRequestParams,
} from "../card-images";

const getCardQualitiesApi = (params: RequestParams) => {
  const url = CardImagesEndpointsEnum.LIST_CARD_QUALITIES;
  return api.get(url, { params });
};

const getCardImageListApi = (params: GetCardImageListRequestParams) => {
  const { shop_id, pack_id, ...restParams } = params;

  const url = CardImagesEndpointsEnum.CARD_IMAGE_LIST.replace(
    ":shopId",
    String(shop_id)
  ).replace(":packId", pack_id ? String(pack_id) : "");

  return api.get(url, { params: { ...restParams, limit: DEFAULT_PER_PAGE } });
};

const deleteCardImageApi = (params: DeleteCardImageRequestParams) => {
  const { cardId, id } = params;

  return id
    ? api.delete(
        CardImagesEndpointsEnum.DELETE_CARD_IMAGE.replace(
          ":cardId",
          String(cardId)
        ),
        {
          params: {
            id,
          },
        }
      )
    : api.delete(
        CardImagesEndpointsEnum.DELETE_CARD_IMAGE.replace(
          ":cardId",
          String(cardId)
        )
      );
};

const editCardImageApi = (cardId: number, data: FormData) => {
  const url = CardImagesEndpointsEnum.EDIT_CARD_IMAGE.replace(
    ":cardId",
    String(cardId)
  );
  return api.post(url, data, {
    transformRequest: () => {
      return data;
    },
  });
};

const createCardImageApi = (cardId: number, data: FormData) => {
  const url = CardImagesEndpointsEnum.CREATE_CARD_IMAGE.replace(
    ":cardId",
    String(cardId)
  );
  return api.post(url, data, {
    transformRequest: () => {
      return data;
    },
  });
};

const saveCardQualityApi = ({
  card_quality_id,
  card_id,
}: {
  card_quality_id: number;
  card_id: number;
}) => {
  const url = CardImagesEndpointsEnum.EDIT_CARD_QUALITY.replace(
    ":cardId",
    String(card_id)
  );
  return api.patch(url, { card_quality_id });
};

const cardImagesApi = {
  getCardImageListApi,
  deleteCardImageApi,
  editCardImageApi,
  createCardImageApi,
  getCardQualitiesApi,
  saveCardQualityApi,
};

export default cardImagesApi;
