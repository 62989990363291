/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import React from "react";

import { ConfigProvider } from "antd";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import store, { persistor } from "@app/redux/store";

import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import antdTheme from "./styles/antd-theme.json";

const render = () => {
  const App = require("./App").default;

  const container = document.getElementById("root") as
    | Element
    | DocumentFragment;

  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <Provider store={store} key="provider">
        <PersistGate loading={null} persistor={persistor}>
          <ConfigProvider
            autoInsertSpaceInButton={false}
            theme={antdTheme}
            renderEmpty={() => {
              return (
                <p className="text-center py-5">
                  データを表示できません
                  <br />
                  設定をお確かめの上リロードしてください
                </p>
              );
            }}
          >
            <App />
          </ConfigProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", render);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
