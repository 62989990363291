import { memo, ReactNode } from "react";

import { Layout } from "antd";
import { Footer } from "antd/es/layout/layout";
import cx from "classnames";

import styles from "./BlankLayout.module.scss";

const { Content } = Layout;
type BlankLayoutProps = {
  children: ReactNode;
};

const BlankLayout = memo(({ children }: BlankLayoutProps) => {
  return (
    <Layout className={cx(styles.root)}>
      <Content>{children}</Content>
      <Footer className={cx(styles.footer, "font-14")}>
        Copyright© Torecaball All Rights Reserved
      </Footer>
    </Layout>
  );
});

export default BlankLayout;
