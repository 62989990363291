import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { DEFAULT_PER_PAGE } from "../../../constants/constants";
import { rolesEndpoints } from "../constants/roles.endpoints";
import {
  PermissionUpdateRequest,
  RoleCreateRequest,
  RolesRequestParams,
} from "../roles";

const getRolesApi = (params?: RolesRequestParams): Promise<AxiosResponse> => {
  return api.get(rolesEndpoints.GET_ALL, {
    params: { ...params, limit: DEFAULT_PER_PAGE },
  });
};

const getRoleDetailApi = (roleId: string): Promise<AxiosResponse> => {
  return api.get(rolesEndpoints.ROLE_DETAIL.replace(":roleId", roleId));
};

const createRoleApi = (data: RoleCreateRequest): Promise<AxiosResponse> => {
  return api.post(rolesEndpoints.ROLE_CREATE, data);
};

const updateRoleApi = ({
  roleId,
  data,
}: {
  roleId: string;
  data: RoleCreateRequest;
}): Promise<AxiosResponse> => {
  return api.patch(rolesEndpoints.ROLE_EDIT.replace(":roleId", roleId), data);
};

const deleteRoleApi = ({
  roleId,
}: {
  roleId: string;
}): Promise<AxiosResponse> => {
  return api.delete(rolesEndpoints.DELETE_ROLE.replace(":roleId", roleId));
};

const getPermissionsApi = (): Promise<AxiosResponse> => {
  return api.get(rolesEndpoints.GET_PERMISSIONS);
};

const updatePermissionApi = ({
  data,
}: {
  data: PermissionUpdateRequest;
}): Promise<AxiosResponse> => {
  return api.patch(rolesEndpoints.UPDATE_PERMISSIONS, data);
};

const rolesApi = {
  getRolesApi,
  deleteRoleApi,
  getPermissionsApi,
  getRoleDetailApi,
  createRoleApi,
  updateRoleApi,
  updatePermissionApi,
};

export default rolesApi;
