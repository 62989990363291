/* eslint-disable no-case-declarations */
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

import { openNotification } from "@app/components/molecules/Notification/notification";
import { AXIOS_ERROR_CODE, TIME_OUT_API } from "@app/constants/api.constants";
import { ERROR_MESSAGES } from "@app/constants/message.constants";
import {
  AuthPathsEnum,
  clearUser,
  getTokenInfo,
  postRefreshToken,
} from "@app/features/auth/auth";
import { redirectToLogin } from "@app/helpers/route.helper";
import { trimValue } from "@app/helpers/trim_values.helper";
import store from "@app/redux/store";
import { router } from "@app/routes/Router";

/**
 * Adds authorization headers to API calls
 * @param {InternalAxiosRequestConfig} request
 */
const authInterceptor = async (request: InternalAxiosRequestConfig) => {
  request.auth = {
    username: process.env.REACT_APP_AUTH_USER ?? "",
    password: process.env.REACT_APP_AUTH_PASSWORD ?? "",
  };

  const requestConfig = trimValue(request) as InternalAxiosRequestConfig;
  const access_token = getTokenInfo()?.access_token;
  if (access_token) {
    requestConfig.headers.AuthorizationAPI = `Bearer ${access_token}`;
  }
  return requestConfig;
};

/**
 * handle error
 */
const errorInterceptor = async (axiosError: AxiosError) => {
  if (axiosError.code === AXIOS_ERROR_CODE.TIME_OUT) {
    openNotification({
      type: "error",
      message: ERROR_MESSAGES.TIMEOUT_ERROR,
    });
    return Promise.reject(axiosError);
  }
  const statusCode = axiosError?.response?.status;

  switch (statusCode) {
    case 500:
      openNotification({
        type: "error",
        message: "error server",
      });
      break;
    case 408: {
      redirectToLogin();
      break;
    }
    case 403:
      router.navigate(AuthPathsEnum.NOT_FOUND);
      break;
    case 404:
      router.navigate(AuthPathsEnum.NOT_FOUND);
      break;
    default:
    // code block
  }
  return Promise.reject(axiosError);
};

/**
 * Function that will be called to refresh authorization
 */
const refreshAuthLogic = async () => {
  const tokenInfo = getTokenInfo();

  if (tokenInfo) {
    return store
      .dispatch(
        postRefreshToken({
          refresh_token: tokenInfo.refresh_token,
          grant_type: "refresh_token",
        })
      )
      .unwrap()
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        redirectToLogin();
        return Promise.reject();
      });
  }

  store.dispatch(clearUser());
  redirectToLogin();
  return Promise.reject();
};

/**
 * Axios success response interceptor
 * @param {AxiosResponse} response
 */
const responseInterceptor = (response: AxiosResponse) => {
  return response;
};

/** Setup an API instance */
export const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  timeout: TIME_OUT_API,
});

createAuthRefreshInterceptor(api, refreshAuthLogic);
api.interceptors.request.use(authInterceptor);
api.interceptors.response.use(responseInterceptor, errorInterceptor);
