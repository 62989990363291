import { Space, SpaceProps, Typography } from "antd";

interface UserPreviewCardProps extends SpaceProps {
  name: string;
  zipCode: string;
  address: string;
  tel: string;
}

function UserPreviewCard({
  name,
  zipCode,
  address,
  tel,
  ...spaceProps
}: UserPreviewCardProps) {
  return (
    <Space {...spaceProps}>
      <Typography.Text>名前: {name}</Typography.Text>
      <Typography.Text>郵便番号: 〒-{zipCode}</Typography.Text>
      <Typography.Text>住所: {address}</Typography.Text>
      <Typography.Text>電話: {tel}</Typography.Text>
    </Space>
  );
}

export default UserPreviewCard;
