import { createSlice } from "@reduxjs/toolkit";

import { createApiAsyncThunk } from "@app/redux/api.thunk";
import { RequestParams } from "@app/types/api.types";

import api from "../api/card-images.api";
import {
  CardImageList,
  CardQualities,
  CARD_IMAGES_FEATURE_KEY,
  GetCardImageListRequestParams,
} from "../card-images";

type CardImagesState = {
  cardImageList: CardImageList | null;
  cardImageLastOneList: CardImageList | null;
  cardQualityList: CardQualities | null;
};

const initialState: CardImagesState = {
  cardImageList: null,
  cardImageLastOneList: null,
  cardQualityList: null,
};

export const getCardQualities = createApiAsyncThunk(
  `${CARD_IMAGES_FEATURE_KEY}/getCardQualities`,
  (params: RequestParams) => {
    return api.getCardQualitiesApi(params);
  }
);

export const getCardImageList = createApiAsyncThunk(
  `${CARD_IMAGES_FEATURE_KEY}/getCardImageList`,
  (params: GetCardImageListRequestParams) => {
    return api.getCardImageListApi(params);
  }
);

export const deleteCardImage = createApiAsyncThunk(
  `${CARD_IMAGES_FEATURE_KEY}/deleteCardImage`,
  (params: { cardId: number; id?: number }) => {
    return api.deleteCardImageApi(params);
  }
);

export const editCardImage = createApiAsyncThunk(
  `${CARD_IMAGES_FEATURE_KEY}/editCardImage`,
  ({ cardId, data }: { cardId: number; data: FormData }) => {
    return api.editCardImageApi(cardId, data);
  }
);

export const createCardImage = createApiAsyncThunk(
  `${CARD_IMAGES_FEATURE_KEY}/createCardImage`,
  ({ cardId, data }: { cardId: number; data: FormData }) => {
    return api.createCardImageApi(cardId, data);
  }
);

export const saveCardQuality = createApiAsyncThunk(
  `${CARD_IMAGES_FEATURE_KEY}/saveCardQuality`,
  (params: { card_quality_id: number; card_id: number }) => {
    return api.saveCardQualityApi(params);
  }
);

const cardImagesSlice = createSlice({
  name: CARD_IMAGES_FEATURE_KEY,
  initialState,
  reducers: {
    resetCardImagesStore: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getCardImageList.fulfilled, (state, action) => {
      if (action.meta.arg.last_one) {
        state.cardImageLastOneList = action.payload;
        return;
      }
      state.cardImageList = action.payload;
    });
    builder.addCase(getCardQualities.fulfilled, (state, action) => {
      state.cardQualityList = action.payload;
    });
  },
});

export const cardImagesReducer = cardImagesSlice.reducer;
export const { resetCardImagesStore } = cardImagesSlice.actions;
