import { memo, useEffect, useMemo, useState } from "react";

import {
  Col,
  Modal,
  Row,
  Image,
  Form,
  Typography,
  Radio,
  ModalProps,
  FormInstance,
} from "antd";
import cx from "classnames";
import { serialize } from "object-to-formdata";

import Button from "@app/components/atoms/Button/Button";
import { Item } from "@app/components/atoms/Form/Form";
import FormUpload from "@app/components/atoms/FormUpload/FormUpload";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { LIST_CODE_SCREEN } from "@app/constants/constants";
import { CARD_STATUS } from "@app/features/cards/cards";
import { isNotGranted } from "@app/helpers/permisstion.helper";
import { getMessageErrors } from "@app/helpers/util.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import styles from "./CardImageDialog.module.scss";
import {
  Card,
  createCardImage,
  deleteCardImage,
  editCardImage,
  getCardQualities,
  saveCardQuality,
} from "../../card-images";
import { FALLBACK_IMAGE } from "../../constants/card-images.constants";

interface CardImageDialogProps extends ModalProps {
  cardImageSelected: Card;
  isLoadingReload: boolean;
  form: FormInstance;
  onCancel: () => void;
  reload: () => void;
}

const CardImageDialog = ({
  cardImageSelected,
  form,
  reload,
  onCancel,
  isLoadingReload,
  ...rest
}: CardImageDialogProps) => {
  const [frontImageUrl, setFrontImageUrl] = useState<string>();
  const [backImageUrl, setBackImageUrl] = useState<string>();
  const [isClearFileList, setIsClearFileList] = useState<boolean>(false);
  const [isEditFrontCard, setIsEditImageFrontCard] = useState(false);
  const [isEditBackCard, setIsEditImageBackCard] = useState(false);

  const dispatch = useAppDispatch();
  const { id: cardId } = cardImageSelected;

  const { cardQualityList } = useAppSelector(
    (state: RootState) => state.cardImages
  );

  const selectCardQualityOptions = useMemo(() => {
    return cardQualityList?.card_qualities?.map(item => ({
      value: item.id,
      label: item.name,
    }));
  }, [cardQualityList?.card_qualities]);

  useEffect(() => {
    dispatch(getCardQualities({}))
      .unwrap()
      .catch(err => getMessageErrors(err));
  }, [dispatch]);

  const { frontCard, backCard } = useMemo(() => {
    const front = cardImageSelected?.card_images?.find(
      item => !!item.is_primary
    );
    const back = cardImageSelected?.card_images?.find(item => !item.is_primary);
    return {
      frontCard: front,
      backCard: back,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardImageSelected?.card_images, isClearFileList]);

  const handleDeleteCardImage = (
    { isFrontSide }: { isFrontSide: boolean },
    id?: number
  ) => {
    return dispatch(deleteCardImage({ cardId, id }))
      .unwrap()
      .then(() => {
        isFrontSide ? setFrontImageUrl(undefined) : setBackImageUrl(undefined);
        isFrontSide
          ? form.setFieldValue("frontSide", undefined)
          : form.setFieldValue("backSide", undefined);
        setIsClearFileList(!isClearFileList);

        reload();
      })
      .then(() => {
        setIsEditImageFrontCard(false);
        setIsEditImageBackCard(false);
      })
      .catch(err => {
        getMessageErrors(err);
      });
  };

  const handleCreateOrEditCardImage = (
    cardImgId: number | undefined,
    { isFrontSide }: { isFrontSide: boolean }
  ) => {
    const image = form.getFieldValue(isFrontSide ? "frontSide" : "backSide");
    const formValues = form.getFieldsValue();
    const { frontSide, backSide } = formValues;

    const payload = cardImgId
      ? { id: cardImgId, image }
      : { image, is_primary: isFrontSide ? 1 : 0 };

    return cardImgId
      ? dispatch(editCardImage({ cardId, data: serialize(payload) }))
          .unwrap()
          .then(() => {
            frontSide &&
              isFrontSide &&
              setFrontImageUrl(URL.createObjectURL(frontSide));
            backSide &&
              !isFrontSide &&
              setBackImageUrl(URL.createObjectURL(backSide));
            reload();
          })
          .then(() => {
            isFrontSide && setIsEditImageFrontCard(true);
            backSide && !isFrontSide && setIsEditImageBackCard(true);
          })
          .catch(err => getMessageErrors(err))
      : dispatch(createCardImage({ cardId, data: serialize(payload) }))
          .unwrap()
          .then(() => {
            frontSide &&
              isFrontSide &&
              setFrontImageUrl(URL.createObjectURL(frontSide));
            backSide &&
              !isFrontSide &&
              setBackImageUrl(URL.createObjectURL(backSide));
            reload();
          })
          .then(() => {
            isFrontSide && setIsEditImageFrontCard(true);
            backSide && !isFrontSide && setIsEditImageBackCard(true);
          })
          .catch(err => getMessageErrors(err));
  };

  const handleSaveCardQuality = (qualityId: number) => {
    return dispatch(
      saveCardQuality({ card_quality_id: qualityId, card_id: cardId })
    )
      .unwrap()
      .then(() => {
        reload();
        form.setFieldsValue({});
      })
      .catch(err => getMessageErrors(err));
  };

  return (
    <Modal
      destroyOnClose
      className={cx(styles.root)}
      width={800}
      footer={null}
      onCancel={onCancel}
      {...rest}
    >
      {cardImageSelected ? (
        <Form form={form}>
          <Typography.Title level={5}>
            画像名: {cardImageSelected.image_name}
          </Typography.Title>
          <Row gutter={20}>
            <Col span={12}>
              <Typography.Text>表</Typography.Text>
              <br />

              <Item shouldUpdate>
                {() => (
                  <Image
                    width={300}
                    src={frontCard?.image_url ?? frontImageUrl}
                    preview={false}
                    className="ml-5 mt-5"
                    fallback={FALLBACK_IMAGE}
                  />
                )}
              </Item>
              <Typography className="mt-2">
                名前: {frontCard?.image_path?.split("/").at(-1)}
              </Typography>
            </Col>
            <Col span={12} className="flex-center flex-direction-column">
              <FormUpload
                key={+isClearFileList}
                form={form}
                reload={() => setIsEditImageFrontCard(false)}
                isUploadImage
                name="frontSide"
                showRemoveIcon={false}
                required
              />

              <Item shouldUpdate>
                {() => (
                  <Button
                    width="300px"
                    danger
                    type="primary"
                    className="mb-6"
                    onSubmit={() =>
                      handleDeleteCardImage(
                        {
                          isFrontSide: true,
                        },
                        frontCard?.id
                      )
                    }
                    disabled={
                      isLoadingReload ||
                      (cardImageSelected.status === CARD_STATUS.CREATED
                        ? !frontCard && !frontImageUrl
                        : !frontCard) ||
                      isNotGranted(LIST_CODE_SCREEN.CARD_IMAGE_DELETE)
                    }
                  >
                    削除
                  </Button>
                )}
              </Item>

              <Item shouldUpdate>
                {({ getFieldValue }) => (
                  <Button
                    width="300px"
                    type="primary"
                    onSubmit={() =>
                      handleCreateOrEditCardImage(frontCard?.id, {
                        isFrontSide: true,
                      })
                    }
                    disabled={
                      isEditFrontCard ||
                      !getFieldValue("frontSide") ||
                      isNotGranted(LIST_CODE_SCREEN.CARD_IMAGE_CREATE_EDIT)
                    }
                  >
                    編集
                  </Button>
                )}
              </Item>
            </Col>
          </Row>

          <Row gutter={20} className="mt-5">
            <Col span={12}>
              <Typography.Text>裏</Typography.Text>
              <br />

              <Item shouldUpdate>
                {() => (
                  <Image
                    width={300}
                    src={backCard?.image_url ?? backImageUrl}
                    preview={false}
                    className="ml-5 mt-5"
                    fallback={FALLBACK_IMAGE}
                  />
                )}
              </Item>
              <Typography className="mt-2">
                名前: {backCard?.image_path?.split("/").at(-1)}
              </Typography>
            </Col>
            <Col span={12} className="flex-center flex-direction-column">
              <FormUpload
                key={+isClearFileList}
                form={form}
                isUploadImage
                reload={() => setIsEditImageBackCard(false)}
                name="backSide"
                showRemoveIcon={false}
                required
              />
              <Item shouldUpdate>
                {() => (
                  <Button
                    width="300px"
                    danger
                    type="primary"
                    className="mb-6"
                    onSubmit={() =>
                      handleDeleteCardImage(
                        {
                          isFrontSide: false,
                        },
                        backCard?.id
                      )
                    }
                    disabled={
                      isLoadingReload ||
                      (cardImageSelected.status === CARD_STATUS.CREATED
                        ? !backCard && !backImageUrl
                        : !backCard) ||
                      isNotGranted(LIST_CODE_SCREEN.CARD_IMAGE_DELETE)
                    }
                  >
                    削除
                  </Button>
                )}
              </Item>
              <Item shouldUpdate>
                {({ getFieldValue }) => (
                  <Button
                    width="300px"
                    type="primary"
                    onSubmit={() =>
                      handleCreateOrEditCardImage(backCard?.id, {
                        isFrontSide: false,
                      })
                    }
                    disabled={
                      isEditBackCard ||
                      !getFieldValue("backSide") ||
                      isNotGranted(LIST_CODE_SCREEN.CARD_IMAGE_CREATE_EDIT)
                    }
                  >
                    編集
                  </Button>
                )}
              </Item>
            </Col>
          </Row>

          <div className="d-flex mt-5">
            <Item
              name="quality"
              className="collection-create-form_last-form-item mb-0"
            >
              <Radio.Group
                defaultValue={cardImageSelected.quality?.id}
                options={selectCardQualityOptions}
              />
            </Item>

            <Item shouldUpdate>
              {({ getFieldValue }) => (
                <Button
                  width="100px"
                  type="primary"
                  onSubmit={() =>
                    handleSaveCardQuality(getFieldValue("quality"))
                  }
                  disabled={
                    !getFieldValue("quality") ||
                    isNotGranted(LIST_CODE_SCREEN.CARD_IMAGE_CREATE_EDIT)
                  }
                >
                  保存
                </Button>
              )}
            </Item>
          </div>
        </Form>
      ) : (
        <LoadingSpinner isFitContent className="py-10" />
      )}
    </Modal>
  );
};

export default memo(CardImageDialog);
