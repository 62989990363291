export const MAINTAIN_FLAG = {
  SERVER_MAINTAIN_FLAG: "server_maintain_flag",
  GACHA_MAINTAIN_FLAG: "gacha_maintain_flag",
};

export const ON_OFF_OPTIONS = [
  {
    value: "0",
    label: "オフ",
  },
  {
    value: "1",
    label: "オン",
  },
];
