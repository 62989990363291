import { api } from "@app/api/api";
import { DEFAULT_PER_PAGE } from "@app/constants/constants";
import { RequestParams } from "@app/types/api.types";

import { CardsEndpointsEnum } from "../cards";

const cardListApi = (params?: RequestParams) => {
  const url = CardsEndpointsEnum.LIST_CARD;
  return api.get(url, { params: { ...params, limit: DEFAULT_PER_PAGE } });
};

const updateCardStatusApi = ({ id }: { id: string }) => {
  const url = CardsEndpointsEnum.UPDATE_CARD_STATUS.replace(":id", id);
  return api.patch(url);
};

const cardApi = {
  cardListApi,
  updateCardStatusApi,
};

export default cardApi;
