import { AxiosResponse } from "axios";

import { api } from "@app/api/api";
import { RequestParams } from "@app/types/api.types";

import {
  ShopsEndpointsEnum,
  UpdateShopDisplayOrderRequestBody,
} from "../shops";

const shopListApi = (params?: RequestParams): Promise<AxiosResponse> => {
  const url = ShopsEndpointsEnum.SHOP;
  return api.get(url, { params });
};

const deleteShopApi = (shopId: string): Promise<AxiosResponse> => {
  const url = ShopsEndpointsEnum.SHOP_DETAIL.replace(":shopId", String(shopId));
  return api.delete(url);
};

const createShopApi = (data: FormData): Promise<AxiosResponse> => {
  const url = ShopsEndpointsEnum.SHOP;
  return api.post(url, data, {
    transformRequest: () => {
      return data;
    },
  });
};

const shopDetailApi = (shopId: string): Promise<AxiosResponse> => {
  const url = ShopsEndpointsEnum.SHOP_DETAIL.replace(":shopId", String(shopId));
  return api.get(url);
};

const updateShopApi = (
  shopId: string,
  data: FormData
): Promise<AxiosResponse> => {
  const url = ShopsEndpointsEnum.SHOP_DETAIL.replace(":shopId", String(shopId));
  return api.post(url, data, {
    params: {
      _method: "PATCH",
    },
    transformRequest: () => {
      return data;
    },
  });
};

const updateShopDisplayOrderApi = ({
  shopId,
  previous_display_order,
}: UpdateShopDisplayOrderRequestBody): Promise<AxiosResponse> => {
  const url = ShopsEndpointsEnum.SHOP_UPDATE_DISPLAY_ORDER.replace(
    ":shopId",
    shopId
  );
  return api.patch(url, { previous_display_order });
};

const shopApi = {
  shopListApi,
  deleteShopApi,
  createShopApi,
  shopDetailApi,
  updateShopApi,
  updateShopDisplayOrderApi,
};

export default shopApi;
