import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosPromise } from "axios";
import { get } from "lodash";

import { AXIOS_ERROR_CODE } from "@app/constants/api.constants";
import { ErrorsResponse } from "@app/types/api.types";

export type ErrorType<Error> = AxiosError<Error>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const errorHandler = (err: ErrorType<any>) => {
  const { request, response, code } = err;
  if (response) {
    // process error to based on type
    const { errors } = response.data;
    return errors as ErrorsResponse;
  }
  if (request && code !== AXIOS_ERROR_CODE.TIME_OUT) {
    // request sent but no response received
    return "network.noInternet";
  }
  // Something happened in setting up the request that triggered an Error
  return "network.unknown";
};

export const createApiAsyncThunk = <Result, ThunkArg>(
  key: string,
  action: (arg: ThunkArg) => AxiosPromise<Result>
) => {
  return createAsyncThunk<Result, ThunkArg>(
    key,
    async (arg, { rejectWithValue }) => {
      try {
        const { data } = await action(arg);
        return get(data, "data", data) as Result;
      } catch (error) {
        return rejectWithValue(errorHandler(error));
      }
    }
  );
};
