import { memo, useEffect, useMemo, useState } from "react";

import { Col, Input, Row, Table, Typography } from "antd";
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import PaginationCustom from "@app/components/atoms/PaginationCustom/PaginationCustom";
import { LIST_CODE_SCREEN } from "@app/constants/constants";
import { isNotGranted } from "@app/helpers/permisstion.helper";
import { getMessageErrors } from "@app/helpers/util.helper";
import useGranted from "@app/hooks/useGranted/useGranted";
import { useAppSelector, useAppDispatch } from "@app/redux/store";

import { CardMastersPathsEnum } from "../../card-masters";
import { CARD_RARES_TABLE_COLUMNS } from "../../constants/card-masters.constant";
import {
  deleteCardRare,
  getCardRaresList,
} from "../../redux/card-masters.slice";

const CardRaresListScreen = () => {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const navigate = useNavigate();
  useGranted({ screenCode: LIST_CODE_SCREEN.CARD_RARE_LIST });
  const [search] = useSearchParams();
  const page = Number(search.get("page") ?? 1);
  const defaultCardRareName = search.get("name") ?? undefined;

  const { cardRareList } = useAppSelector(state => state.cardMasters);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [triggerReload, setTriggerReload] = useState<boolean>(false);

  useEffect(() => {
    dispatch(
      getCardRaresList({
        name: defaultCardRareName ?? undefined,
        page,
        use_page: "true",
      })
    )
      .unwrap()
      .catch(err => getMessageErrors(err))
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, defaultCardRareName, page, triggerReload]);

  const handleDeleteCardRare = (id: string) => {
    return dispatch(deleteCardRare(id))
      .unwrap()
      .then(() => setTriggerReload(!triggerReload))
      .catch(err => {
        getMessageErrors(err);
      });
  };

  const handleSearch = () => {
    const { name } = form.getFieldsValue();
    const params = {
      name: name || undefined,
    };

    navigate({
      pathname: CardMastersPathsEnum.LIST_CARD_RARES,
      search: `?${createSearchParams(JSON.parse(JSON.stringify(params)))}`,
    });

    return false;
  };

  const cardRaresListConvert = useMemo(() => {
    return cardRareList?.card_rares.map(item => ({
      ...item,
      key: item.id,
      action: (
        <div className="flex-space-between">
          <Button
            type="primary"
            disabled={isNotGranted(LIST_CODE_SCREEN.CARD_RARE_EDIT)}
            onClick={() => {
              navigate(
                CardMastersPathsEnum.EDIT_CARD_RARE.replace(
                  ":id",
                  String(item.id)
                ),
                {
                  state: {
                    cardRare: item,
                  },
                }
              );
            }}
          >
            編集
          </Button>
          <Button
            danger
            isShowConfirm
            disabled={isNotGranted(LIST_CODE_SCREEN.CARD_RARE_DETELE)}
            title="このレアを削除しましすか？"
            type="primary"
            onSubmit={() => handleDeleteCardRare(String(item.id))}
          >
            削除
          </Button>
        </div>
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardRareList?.card_rares]);

  return (
    <>
      <Typography.Title level={3}>カードのレア度一覧</Typography.Title>
      <Form form={form} onFinish={handleSearch}>
        <Row>
          <Col span={12}>
            <Item
              name="name"
              label="カードのレア度"
              initialValue={defaultCardRareName}
            >
              <Input size="large" allowClear />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Button style={{ width: "300px" }} type="primary" htmlType="submit">
              検索
            </Button>
          </Col>
        </Row>
      </Form>
      <Button
        onClick={() => navigate(CardMastersPathsEnum.CREATE_CARD_RARE)}
        disabled={isNotGranted(LIST_CODE_SCREEN.CARD_RARE_CREATE)}
        className="mt-4"
        type="primary"
        width="300px"
      >
        新規追加
      </Button>
      <Table
        bordered
        rowKey="id"
        className="mt-10 mb-8"
        pagination={false}
        loading={isLoading}
        dataSource={cardRaresListConvert}
        columns={CARD_RARES_TABLE_COLUMNS}
        scroll={{ x: 1000 }}
      />
      <PaginationCustom
        current={page}
        total={cardRareList?.page_info?.total_count}
      />
    </>
  );
};

export default memo(CardRaresListScreen);
