export const DURATION_NOTIFICATION = 5;
export const PAGINATION_SIZE = 50;
export const DEFAULT_PER_PAGE = 50;

export const LIST_CODE_SCREEN = {
  SHOP_LIST: "shop_list",
  SHOP_CREATE: "shop_create",
  SHOP_EDIT: "shop_edit",
  SHOP_BUTTON_SEND_MAIL: "shop_button_send_mail",
  SHOP_DELETE: "shop_delete",
  SHOP_SORT: "shop_sort",
  PACK_LIST: "pack_list",
  PACK_CREATE: "pack_create",
  PACK_EDIT: "pack_edit",
  PACK_PUBLISH: "pack_publish",
  PACK_DELETE: "pack_delete",
  BOX_REGISTER: "box_register",
  CARD_IMAGE_LIST: "card_image_list",
  CARD_IMAGE_CREATE_EDIT: "card_image_create_edit",
  CARD_IMAGE_DELETE: "card_image_delete",
  CARD_QUALITY_REGISTER: "card_quality_register",
  SETTING_LIST: "setting_list",
  SETTING_EDIT: "setting_edit",
  BANK_TRANSFER_LIST: "bank_transfer_list",
  BANK_TRANSFER_PAYMENT: "bank_transfer_payment",
  USER_LIST: "user_list",
  USER_BUTTON_SEND_MAIL: "user_button_send_mail",
  CARD_OF_USER_LIST: "card_of_user_list",
  CARD_COMPLETE_SENT: "card_complete_sent",
  USER_DETAIL: "user_detail",
  USER_ADD_POINT: "user_add_point",
  USER_DELETE_POINT: "user_delete_point",
  USER_DELETE: "user_delete",
  BANNER_LIST: "banner_list",
  BANNER_CREATE: "banner_create",
  BANNER_EDIT: "banner_edit",
  BANNER_DELETE: "banner_delete",
  ROLE_LIST: "role_list",
  ROLE_CREATE: "role_create",
  ROLE_EDIT: "role_edit",
  ROLE_DELETE: "role_delete",
  PERMISSION_EDIT: "permission_edit",
  ADMIN_LIST: "admin_list",
  ADMIN_CREATE: "admin_create",
  ADMIN_SEND_MAIL: "admin_send_mail",
  ADMIN_DETAIL: "admin_detail",
  ADMIN_DELETE: "admin_delete",
  ADMIN_RESEND_VERIFICATION_EMAIL: "admin_resend_verification_email",
  ADMIN_MYPAGE: "admin_mypage",
  ADMIN_CURRENT_EDIT: "admin_current_edit",
  ADMIN_EDIT: "admin_edit",
  PACK_EXPORT: "pack_export",
  LABEL_LIST: "label_list",
  LABEL_CREATE: "label_create",
  LABEL_EDIT: "label_edit",
  LABEL_DELETE: "label_delete",
  LABEL_SORT: "label_sort",
  CARD_RARE_LIST: "card_rare_list",
  CARD_RARE_CREATE: "card_rare_create",
  CARD_RARE_EDIT: "card_rare_edit",
  CARD_RARE_DETELE: "card_rare_delete",
  CARD_MASTER_LIST: "card_master_list",
};

export const MENU_ITEMS = [
  {
    key: "1",
    label: "企業登録",
    pathName: "/shops",
    code: LIST_CODE_SCREEN.SHOP_LIST,
  },
  {
    key: "2",
    label: "パック一覧",
    pathName: "/packs",
    code: LIST_CODE_SCREEN.PACK_LIST,
  },
  {
    key: "3",
    label: "カード登録",
    pathName: "/card-registration",
    code: LIST_CODE_SCREEN.BOX_REGISTER,
  },
  {
    key: "4",
    label: "ユーザー詳細",
    pathName: "/users",
    code: LIST_CODE_SCREEN.USER_LIST,
  },
  {
    key: "5",
    label: "編集した画像カードを追加",
    pathName: "/card-images",
    code: LIST_CODE_SCREEN.CARD_IMAGE_LIST,
  },
  {
    key: "6",
    label: "設定",
    pathName: "/settings",
    code: LIST_CODE_SCREEN.SETTING_LIST,
  },
  {
    key: "7",
    label: "銀行振り込み",
    pathName: "/bank-transfer",
    code: LIST_CODE_SCREEN.BANK_TRANSFER_LIST,
  },
  {
    key: "8",
    label: "バナー一覧画面",
    pathName: "/banners",
    code: LIST_CODE_SCREEN.BANNER_LIST,
  },
  {
    key: "9",
    label: "ロール一覧画面",
    pathName: "/roles",
    code: LIST_CODE_SCREEN.ROLE_LIST,
  },
  {
    key: "10",
    label: "ラベル管理",
    pathName: "/labels",
    code: LIST_CODE_SCREEN.LABEL_LIST,
  },
  {
    key: "11",
    label: "管理画面ユーザー管理",
    pathName: "/admins",
    code: LIST_CODE_SCREEN.ADMIN_LIST,
  },
  {
    key: "12",
    label: "カードのレア度一覧",
    pathName: "/card-rares",
    code: LIST_CODE_SCREEN.CARD_RARE_LIST,
  },
  {
    key: "13",
    label: "マスターカード一覧",
    pathName: "/card-masters",
    code: LIST_CODE_SCREEN.CARD_MASTER_LIST,
  },
];
