import { memo, useEffect, useMemo, useState } from "react";

import { Col, Row, Table, Input, Select, Typography } from "antd";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import PaginationCustom from "@app/components/atoms/PaginationCustom/PaginationCustom";
import { LIST_CODE_SCREEN } from "@app/constants/constants";
import { getPacksList } from "@app/features/packs/packs";
import { getShopList } from "@app/features/shops/shops";
import { isNotGranted } from "@app/helpers/permisstion.helper";
import { getAvailableShops, getMessageErrors } from "@app/helpers/util.helper";
import useGranted from "@app/hooks/useGranted/useGranted";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import {
  Card,
  CardImage,
  CardImagesPathsEnum,
  deleteCardImage,
  getCardImageList,
  resetCardImagesStore,
} from "../../card-images";
import CardImageDialog from "../../components/CardImageDialog/CardImageDialog";
import {
  CARD_IMAGE_TYPES,
  COLUMNS_TABLE_CARD_IMAGES,
} from "../../constants/card-images.constants";
import {
  getCardImageNameBySide,
  getCardImageStatus,
} from "../../helpers/card-images.helper";

const CardImageListScreen = () => {
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [isPackOptionsLoading, setIsPackOptionsLoading] =
    useState<boolean>(false);
  const [triggerReload, setTriggerReload] = useState<boolean>(false);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [cardImageSelected, setCardImageSelected] = useState<Card | undefined>(
    undefined
  );
  const [cardImageSelectedLastOne, setCardImageSelectedLastOne] = useState<
    Card | undefined
  >(undefined);
  const [form] = useForm();
  const [formModal] = useForm();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { shopList } = useAppSelector((state: RootState) => state.shops);
  const { packsList } = useAppSelector((state: RootState) => state.packs);
  const { cardImageList } = useAppSelector(state => state.cardImages);
  const { cardImageLastOneList } = useAppSelector(state => state.cardImages);
  useGranted({ screenCode: LIST_CODE_SCREEN.CARD_IMAGE_LIST });

  const navigate = useNavigate();

  const page = Number(searchParams.get("page") ?? 1);
  const defaultShopId = searchParams.get("shop_id");
  const defaultPackId = searchParams.get("pack_id");
  const defaultKeyword = searchParams.get("keyword");

  const selectShopsOptions = useMemo(() => {
    return getAvailableShops(shopList?.shops);
  }, [shopList?.shops]);

  const selectPacksOptions = useMemo(() => {
    return packsList?.packs?.map(item => ({
      value: item.id,
      label: item.title,
    }));
  }, [packsList?.packs]);

  const handleDeleteCardImage = (cardId: number) => {
    return dispatch(deleteCardImage({ cardId }))
      .unwrap()
      .then(() => setTriggerReload(!triggerReload))
      .catch(err => {
        getMessageErrors(err);
      });
  };

  const cardImageListConvert = useMemo(() => {
    return cardImageList?.cards.map(item => {
      return {
        imageName: item.image_name,
        frontSide: getCardImageNameBySide(item.card_images, {
          isFrontSide: true,
        }),
        backSide: getCardImageNameBySide(item.card_images, {
          isFrontSide: false,
        }),
        status: getCardImageStatus(item.card_images),
        action: (
          <div className="flex-space-between">
            <Button
              type="primary"
              disabled={
                !!item.card_images.length ||
                isNotGranted(LIST_CODE_SCREEN.CARD_IMAGE_CREATE_EDIT)
              }
              onClick={() => {
                setCardImageSelected(item);
                setIsOpenDialog(true);
              }}
            >
              新規追加
            </Button>
            <Button
              type="primary"
              disabled={
                !item.card_images.length ||
                isNotGranted(LIST_CODE_SCREEN.CARD_IMAGE_CREATE_EDIT)
              }
              className="ml-3"
              onClick={() => {
                setCardImageSelected(item);
                setIsOpenDialog(true);
              }}
            >
              編集
            </Button>
            <Button
              danger
              type="primary"
              isShowConfirm
              className="ml-3"
              onSubmit={() => handleDeleteCardImage(item.id)}
              disabled={
                !item.card_images.length ||
                isNotGranted(LIST_CODE_SCREEN.CARD_IMAGE_DELETE)
              }
            >
              削除
            </Button>
          </div>
        ),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardImageList]);

  const cardImageLastOneListConvert = useMemo(() => {
    return cardImageLastOneList?.cards.map(item => {
      return {
        imageName: item.image_name,
        frontSide: getCardImageNameBySide(item.card_images, {
          isFrontSide: true,
        }),
        backSide: getCardImageNameBySide(item.card_images, {
          isFrontSide: false,
        }),
        status: getCardImageStatus(item.card_images),
        action: (
          <div className="flex-space-between">
            <Button
              type="primary"
              disabled={
                !!item.card_images.length ||
                isNotGranted(LIST_CODE_SCREEN.CARD_IMAGE_CREATE_EDIT)
              }
              onClick={() => {
                setCardImageSelectedLastOne(item);
                setIsOpenDialog(true);
              }}
            >
              新規追加
            </Button>
            <Button
              type="primary"
              disabled={
                !item.card_images.length ||
                isNotGranted(LIST_CODE_SCREEN.CARD_IMAGE_CREATE_EDIT)
              }
              className="ml-3"
              onClick={() => {
                setCardImageSelectedLastOne(item);
                setIsOpenDialog(true);
              }}
            >
              編集
            </Button>
            <Button
              danger
              type="primary"
              isShowConfirm
              className="ml-3"
              onSubmit={() => handleDeleteCardImage(item.id)}
              disabled={
                !item.card_images.length ||
                isNotGranted(LIST_CODE_SCREEN.CARD_IMAGE_DELETE)
              }
            >
              削除
            </Button>
          </div>
        ),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardImageLastOneList]);

  useEffect(() => {
    if (defaultShopId) {
      dispatch(getShopList({}))
        .unwrap()
        .then(() => {
          form.setFieldValue("shop_id", Number(defaultShopId));
        })
        .then(() => {
          dispatch(
            getPacksList({
              shop_id: Number(defaultShopId),
            })
          )
            .unwrap()
            .then(() => {
              form.setFieldValue("pack_id", Number(defaultPackId));
            })
            .catch(err => getMessageErrors(err));
        })
        .catch(err => getMessageErrors(err));
    } else {
      dispatch(getShopList({}))
        .unwrap()
        .catch(err => getMessageErrors(err));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultShopId, defaultPackId]);

  useEffect(() => {
    return () => {
      dispatch(resetCardImagesStore());
    };
  }, [dispatch]);

  useEffect(() => {
    const params = {
      shop_id: defaultShopId,
      pack_id: defaultPackId,
      keyword: defaultKeyword,
    };

    if (defaultShopId && defaultPackId) {
      setIsTableLoading(true);
      dispatch(
        getCardImageList({
          ...params,
          types: [CARD_IMAGE_TYPES.LAST_ONE],
          last_one: true,
          use_page: "false",
        })
      )
        .then(res => {
          cardImageSelectedLastOne &&
            setCardImageSelectedLastOne(
              res.payload.cards.filter(
                (card: CardImage) => card.id === cardImageSelectedLastOne.id
              )[0]
            );
        })
        .finally(() => {
          setIsTableLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultKeyword,
    defaultPackId,
    defaultShopId,
    dispatch,
    page,
    triggerReload,
  ]);

  useEffect(() => {
    const params = {
      shop_id: defaultShopId,
      pack_id: defaultPackId,
      keyword: defaultKeyword,
    };

    if (defaultShopId && defaultPackId) {
      setIsTableLoading(true);
      dispatch(
        getCardImageList({
          ...params,
          page,
          types: [CARD_IMAGE_TYPES.MEDIUM_HIT, CARD_IMAGE_TYPES.BIG_HIT],
          last_one: false,
          use_page: "true",
        })
      )
        .unwrap()
        .then(res => {
          cardImageSelected &&
            setCardImageSelected(
              res.cards.filter(
                (card: CardImage) => card.id === cardImageSelected.id
              )[0]
            );
        })
        .finally(() => {
          setIsTableLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultKeyword,
    defaultPackId,
    defaultShopId,
    dispatch,
    page,
    triggerReload,
  ]);

  const handleSelectShop = (shop_id: number) => {
    setIsPackOptionsLoading(true);

    dispatch(
      getPacksList({
        shop_id,
      })
    )
      .unwrap()
      .catch(err => getMessageErrors(err))
      .finally(() => {
        setIsPackOptionsLoading(false);
        form.setFieldValue("pack_id", undefined);
      });
  };

  const handleSubmitForm = () => {
    const { shop_id, pack_id, keyword } = form.getFieldsValue();
    const params = {
      shop_id: shop_id || undefined,
      pack_id: pack_id || undefined,
      keyword: keyword || undefined,
    };

    navigate({
      pathname: CardImagesPathsEnum.CARD_IMAGE_LIST,
      search: `?${createSearchParams(JSON.parse(JSON.stringify(params)))}`,
    });
  };

  return (
    <>
      <Typography.Title level={3} className="pb-4">
        編集した画像カードを追加
      </Typography.Title>
      <Form form={form} onFinish={handleSubmitForm}>
        <Row gutter={12}>
          <Col span={6}>
            <Item name="shop_id" label="企業名" shouldUpdate>
              <Select
                loading={!selectShopsOptions}
                options={selectShopsOptions}
                onChange={handleSelectShop}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item name="pack_id" label="ルーム">
              <Select
                loading={isPackOptionsLoading}
                options={selectPacksOptions}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item name="keyword" label="対象カード名">
              <Input size="large" />
            </Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={6}>
            <Item shouldUpdate>
              {({ getFieldValue }) => (
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  disabled={
                    isTableLoading ||
                    !getFieldValue("shop_id") ||
                    !getFieldValue("pack_id")
                  }
                >
                  検索
                </Button>
              )}
            </Item>
          </Col>
        </Row>
      </Form>

      <Table
        className="mt-5"
        bordered
        pagination={false}
        scroll={{ x: 1500 }}
        columns={COLUMNS_TABLE_CARD_IMAGES}
        dataSource={cardImageListConvert}
        loading={isTableLoading}
      />
      <div className="mt-8 text-center pb-5">
        <PaginationCustom
          current={page}
          total={cardImageList?.page_info?.total_count}
        />
      </div>

      {cardImageSelected && (
        <CardImageDialog
          form={formModal}
          open={isOpenDialog}
          isLoadingReload={isTableLoading}
          cardImageSelected={cardImageSelected}
          onCancel={() => {
            setIsOpenDialog(false);
            setCardImageSelected(undefined);
            formModal.resetFields();
          }}
          reload={() => setTriggerReload(!triggerReload)}
        />
      )}

      {cardImageSelectedLastOne && (
        <CardImageDialog
          form={formModal}
          open={isOpenDialog}
          isLoadingReload={isTableLoading}
          cardImageSelected={cardImageSelectedLastOne}
          onCancel={() => {
            setIsOpenDialog(false);
            setCardImageSelectedLastOne(undefined);
            formModal.resetFields();
          }}
          reload={() => setTriggerReload(!triggerReload)}
        />
      )}

      <div className="mb-10">
        <Typography.Title level={3} className="pb-4">
          ラストワン
        </Typography.Title>

        <Table
          className="mt-5"
          bordered
          pagination={false}
          scroll={{ x: 1500 }}
          columns={COLUMNS_TABLE_CARD_IMAGES}
          dataSource={cardImageLastOneListConvert}
          loading={isTableLoading}
        />
      </div>
    </>
  );
};

export default memo(CardImageListScreen);
