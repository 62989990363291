import store from "@app/redux/store";

import { AuthState } from "../types/auth.types";

export const authErrorHelper = (state: AuthState) => {
  state.currentUser = null;
  state.loading = false;
  state.tokenInfo = null;
};

export const getTokenInfo = () => store.getState().auth.tokenInfo;
