import { memo } from "react";

import { Typography } from "antd";

import { LIST_CODE_SCREEN } from "@app/constants/constants";
import { createLabel } from "@app/features/labels/labels";
import useGranted from "@app/hooks/useGranted/useGranted";
import { useAppDispatch } from "@app/redux/store";

import FormDataLabel from "../../components/FormDataLabel";

const CreateLabelScreen = () => {
  const dispatch = useAppDispatch();
  useGranted({ screenCode: LIST_CODE_SCREEN.LABEL_CREATE });

  const handleCreateLabel = (data: FormData) => {
    return dispatch(createLabel(data)).unwrap();
  };

  return (
    <div>
      <Typography.Title level={3}>ラベル作成画面</Typography.Title>
      <FormDataLabel onSubmit={data => handleCreateLabel(data)} />
    </div>
  );
};

export default memo(CreateLabelScreen);
