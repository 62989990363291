import { Params, redirect } from "react-router-dom";

import { AuthPathsEnum } from "@app/features/auth/auth";

const authenticatedLoader = async ({
  params,
  isAuthRoute,
  isProtectedRoute,
}: {
  params: Params<string>;
  isAuthRoute?: boolean;
  isProtectedRoute?: boolean;
}) => {
  const isInvalidParamsId = Object.keys(params).some(
    item => item.match(/id/gim) && !params[item]?.match(/^\d{1,10}$/g)
  );

  if (isInvalidParamsId) {
    return redirect(AuthPathsEnum.NOT_FOUND);
  }

  return { isAuthRoute, isProtectedRoute };
};

export default authenticatedLoader;
