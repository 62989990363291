import { RouteItem } from "@app/types/route.types";

import { CardPathsEnum } from "../cards";
import CardListUserScreen from "../screens/CardListUserScreen/CardListUserScreen";

const CARD_LIST_SCREEN: RouteItem = {
  id: "AD-0011",
  path: CardPathsEnum.LIST_CARD,
  component: CardListUserScreen,
};

export const CARDS_ROUTES = [CARD_LIST_SCREEN];
