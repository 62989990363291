import { lazy } from "react";

import { RouteItem } from "@app/types/route.types";

import { SettingsPathsEnum } from "../constants/settings.paths";

const SETTINGS_LIST_SCREEN: RouteItem = {
  id: "setting",
  path: SettingsPathsEnum.SETTINGS_LIST,
  component: lazy(
    () => import("../screens/SettingsListScreen/SettingsListScreen")
  ),
};

export const SETTINGS_ROUTES = [SETTINGS_LIST_SCREEN];
