/* eslint-disable @typescript-eslint/no-explicit-any */
import { RefObject, useCallback, useEffect } from "react";

import { usePrevious } from "ahooks";
import { Form as AntdForm, FormItemProps } from "antd";
import { FormProps as AntdFormProps, FormInstance } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import _isEqual from "lodash/isEqual";

export type FormValues = any;

export type FormErrors = Record<string, string | string[]> | undefined;

export interface FormProps extends AntdFormProps {
  values?: FormValues;
  formRef?: RefObject<FormInstance>;
  children?: any;
}

function Form({
  values = undefined,
  children,
  formRef = undefined,
  form,
  ...props
}: FormProps) {
  const [formInstance] = useForm(form);

  const bindValues = useCallback(() => {
    formInstance.resetFields();
  }, [formInstance]);

  const prevValues = usePrevious(values);

  useEffect(() => {
    if (!_isEqual(prevValues, values)) {
      bindValues();
    }
  }, [bindValues, values, prevValues]);

  return (
    <AntdForm
      layout="vertical"
      validateTrigger="onBlur"
      form={formInstance}
      ref={formRef}
      initialValues={values}
      onValuesChange={field => {
        form?.setFields([{ name: Object.keys(field), errors: undefined }]);
      }}
      {...props}
    >
      {children}
    </AntdForm>
  );
}

export function Item({ ...rest }: FormItemProps) {
  return <AntdForm.Item validateFirst {...rest} />;
}

export const { List } = AntdForm;

export default Form;

export { useForm };
