import { RouteItem } from "@app/types/route.types";

import { CardImagesPathsEnum } from "../card-images";
import CardImageListScreen from "../screens/CardImagesScreen/CardImagesScreen";

const CARD_IMAGE_LIST_SCREEN: RouteItem = {
  id: "AD-0013-1",
  path: CardImagesPathsEnum.CARD_IMAGE_LIST,
  component: CardImageListScreen,
};

export const CARD_IMAGES_ROUTES = [CARD_IMAGE_LIST_SCREEN];
