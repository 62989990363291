/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line filenames/match-regex
import { get } from "lodash";

export type ObjectType = {
  [key: string]: unknown;
};
export const isArray = (d: any) => Array.isArray(d);
export const isObject = (d: any) =>
  d === Object(d) && !isArray(d) && typeof d !== "function";

export function trimValue<T extends unknown>(d: T): T | ObjectType {
  if (typeof d === "string") {
    return d.trim() as T;
  }

  if (isObject(d)) {
    const o: ObjectType = {};
    Object.keys(d as Record<string, unknown>).forEach(k => {
      o[k] = trimValue(get(d, k) as T);
    });

    return o;
  }

  if (isArray(d)) {
    return (d as Array<unknown>).map((i: unknown) => trimValue(i)) as T;
  }

  return d;
}
