import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import {
  AdminParams,
  AuthUserDataDef,
  LoginRequestBody,
  ResetPasswordDataDef,
} from "../auth";
import { AuthEndpoints } from "../constants/auth.endpoints";

const authLoginApi = (data: LoginRequestBody): Promise<AxiosResponse> => {
  return api.post(AuthEndpoints.LOGIN, data);
};

const authLogoutApi = (): Promise<AxiosResponse> => {
  return api.post(AuthEndpoints.LOGOUT);
};

const getCurrentUserApi = (): Promise<AxiosResponse> => {
  return api.get(AuthEndpoints.GET_CURRENT_USER);
};

const getInformationUserApi = (): Promise<AxiosResponse> => {
  return api.get(AuthEndpoints.MY_PAGE);
};

const updateInformationUserApi = (
  data: AdminParams
): Promise<AxiosResponse> => {
  return api.patch(AuthEndpoints.MY_PAGE_UPDATE, data);
};

const forgotPasswordApi = (data: AuthUserDataDef): Promise<AxiosResponse> => {
  const url = AuthEndpoints.FORGOT_PASSWORD;
  return api.post(url, data);
};

const checkTokenResetPasswordApi = (token: string): Promise<AxiosResponse> => {
  const url = AuthEndpoints.CONFIRM_RESET_PASSWORD.replace(":token", token);
  return api.get(url);
};

const resetPasswordApi = (
  data: ResetPasswordDataDef
): Promise<AxiosResponse> => {
  const url = AuthEndpoints.RESET_PASSWORD;
  return api.post(url, data);
};

const authApi = {
  authLoginApi,
  authLogoutApi,
  getCurrentUserApi,
  getInformationUserApi,
  updateInformationUserApi,
  forgotPasswordApi,
  checkTokenResetPasswordApi,
  resetPasswordApi,
};

export default authApi;
