import { RouteItem } from "@app/types/route.types";

import { CardMastersPathsEnum } from "../constants/card-masters.paths";
import CardRaresListScreen from "../screens/CardRaresListScreen/CardRaresListScreen";
import CreateCardRareScreen from "../screens/CreateCardRareScreen/CreateCardRareScreen";
import EditCardRareScreen from "../screens/EditCardRareScreen/EditCardRareScreen";
import ListCardMastersScreen from "../screens/ListCardMastersScreen/ListCardMastersScreen";

const LIST_CARD_RARES_SCREEN: RouteItem = {
  id: "AD-0024",
  path: CardMastersPathsEnum.LIST_CARD_RARES,
  component: CardRaresListScreen,
};

const EDIT_CARD_RARES_SCREEN: RouteItem = {
  id: "AD-0025-edit",
  path: CardMastersPathsEnum.EDIT_CARD_RARE,
  component: EditCardRareScreen,
};

const CREATE_CARD_RARE_SCREEN: RouteItem = {
  id: "AD-0025-create",
  path: CardMastersPathsEnum.CREATE_CARD_RARE,
  component: CreateCardRareScreen,
};

const LIST_CARD_MASTERS_SCREEN: RouteItem = {
  id: "AD_0026",
  path: CardMastersPathsEnum.LIST_CARD_MASTERS,
  component: ListCardMastersScreen,
};

export const CARD_MASTERS_ROUTES = [
  LIST_CARD_RARES_SCREEN,
  EDIT_CARD_RARES_SCREEN,
  CREATE_CARD_RARE_SCREEN,
  LIST_CARD_MASTERS_SCREEN,
];
