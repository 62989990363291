import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import BlankLayout from "@app/components/layouts/BlankLayout/BlankLayout";

import { routeWrapper } from "./RouteWrapper";
import NotFound from "./components/NotFound/NotFound";
import { PRIVATE_LIST, PUBLIC_LIST } from "./routes.config";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<NotFound />} path="/">
      {PRIVATE_LIST.map(route =>
        routeWrapper(route, { isProtectedRoute: true })
      )}
      {PUBLIC_LIST.map(route => routeWrapper(route))}
      <Route
        path="*"
        element={
          <BlankLayout>
            <NotFound />
          </BlankLayout>
        }
      />
    </Route>
  )
);
