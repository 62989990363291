import { lazy } from "react";

import { RouteItem } from "@app/types/route.types";

import { RolesPathsEnum } from "../roles";

const ROLES_MANAGER_SCREEN: RouteItem = {
  id: "AD-0015",
  path: RolesPathsEnum.ROLE_LIST,
  component: lazy(
    () => import("../screens/RolesManagerScreen/RolesManagerScreen")
  ),
};

const PERMISSION_SCREEN: RouteItem = {
  id: "permission",
  path: RolesPathsEnum.PERMISSION,
  component: lazy(() => import("../screens/PermissionScreen/PermissionScreen")),
};

const PERMISSION_ONE_ROLE_SCREEN: RouteItem = {
  id: "permission",
  path: RolesPathsEnum.PERMISSION_ONE_ROLE,
  component: lazy(() => import("../screens/PermissionScreen/PermissionScreen")),
};

const ROLE_CREATE_SCREEN: RouteItem = {
  id: "AD-0016",
  path: RolesPathsEnum.CREATE_ROLE,
  component: lazy(() => import("../screens/RoleCreateScreen/RoleCreateScreen")),
};

const ROLE_EDIT_SCREEN: RouteItem = {
  id: "AD-0016-EDIT",
  path: RolesPathsEnum.EDIT_ROLE,
  component: lazy(() => import("../screens/RoleEditScreen/RoleEditScreen")),
};

export const ROLES_ROUTES = [
  ROLES_MANAGER_SCREEN,
  PERMISSION_SCREEN,
  ROLE_CREATE_SCREEN,
  ROLE_EDIT_SCREEN,
  PERMISSION_ONE_ROLE_SCREEN,
];
