export const AuthEndpoints = {
  LOGIN: "/login",
  LOGOUT: "/logout",
  GET_CURRENT_USER: "/admins/current",
  MY_PAGE: "/admins/mypage",
  MY_PAGE_UPDATE: "/admins",
  FORGOT_PASSWORD: "/send-reset-password",
  CONFIRM_RESET_PASSWORD: "/reset-password/:token",
  RESET_PASSWORD: "/reset-password",
};
