import { CardImage } from "../card-images";
import { CARD_IMAGE_STATUS } from "../constants/card-images.constants";

export const getCardImageStatus = (cardImages: CardImage[]) => {
  return cardImages.length === 0
    ? CARD_IMAGE_STATUS.NOT_ENTERED
    : CARD_IMAGE_STATUS.ENTERED;
};

export const getCardImageNameBySide = (
  cardImages: CardImage[],
  {
    isFrontSide,
  }: {
    isFrontSide: boolean;
  }
) => {
  const item = cardImages.find(
    cardImage => Boolean(cardImage.is_primary) === isFrontSide
  );

  return item?.image_path ?? "";
};
