import { memo } from "react";

import { Typography } from "antd";

import { LIST_CODE_SCREEN } from "@app/constants/constants";
import { getMessageErrors } from "@app/helpers/util.helper";
import useGranted from "@app/hooks/useGranted/useGranted";
import { useAppDispatch } from "@app/redux/store";

import { createCardRare } from "../../card-masters";
import FormDataCardRare from "../../components/FormDataCardRare";

const CreateLabelScreen = () => {
  const dispatch = useAppDispatch();
  useGranted({ screenCode: LIST_CODE_SCREEN.CARD_RARE_CREATE });

  const handleCreateCardRare = (data: FormData) => {
    return dispatch(createCardRare(data))
      .unwrap()
      .catch(err => {
        getMessageErrors(err);
      });
  };

  return (
    <div>
      <Typography.Title level={3}>新規レア度登録</Typography.Title>
      <FormDataCardRare onSubmit={data => handleCreateCardRare(data)} />
    </div>
  );
};

export default memo(CreateLabelScreen);
