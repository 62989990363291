import { RouteItem } from "@app/types/route.types";

import CreateShopScreen from "../screens/CreateShopScreen/CreateShopScreen";
import EditShopScreen from "../screens/EditShopScreen/EditShopScreen";
import ShopListScreen from "../screens/ShopListScreen/ShopListScreen";
import { ShopPathsEnum } from "../shops";

const SHOP_LIST_SCREEN: RouteItem = {
  id: "shops",
  path: ShopPathsEnum.SHOP_LIST,
  component: ShopListScreen,
};

const CREATE_SHOP_SCREEN: RouteItem = {
  id: "create_shop",
  path: ShopPathsEnum.CREATE_SHOP,
  component: CreateShopScreen,
};

const EDIT_SHOP_SCREEN: RouteItem = {
  id: "edit_shop",
  path: ShopPathsEnum.EDIT_SHOP,
  component: EditShopScreen,
};

export const SHOPS_ROUTES = [
  SHOP_LIST_SCREEN,
  CREATE_SHOP_SCREEN,
  EDIT_SHOP_SCREEN,
];
