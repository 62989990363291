import { RouteItem } from "@app/types/route.types";

import { BannersPathsEnum } from "../constants/banners.paths";
import BannerListScreen from "../screens/BannerListScreen/BannerListScreen";
import CreateBannerScreen from "../screens/CreateBannerScreen/CreateBannerScreen";
import EditBannerScreen from "../screens/EditBannerScreen/EditBannerScreen";

const BANNER_LIST_SCREEN: RouteItem = {
  id: "AD-0013-1",
  path: BannersPathsEnum.LIST_BANNER,
  component: BannerListScreen,
};

const CREATE_BANNER_SCREEN: RouteItem = {
  id: "AD-0013-2",
  path: BannersPathsEnum.CREATE_BANNER,
  component: CreateBannerScreen,
};

const EDIT_BANNER_SCREEN: RouteItem = {
  id: "AD-0013-3",
  path: BannersPathsEnum.EDIT_BANNER,
  component: EditBannerScreen,
};

export const BANNERS_ROUTES = [
  BANNER_LIST_SCREEN,
  CREATE_BANNER_SCREEN,
  EDIT_BANNER_SCREEN,
];
