export const adminsEndpoints = {
  GET_ALL: "/admins",
  RESEND_EMAIL: "/admins/resend-verification-email",
  MODIFY_ADMIN: "/admins/:adminId",
  CREATE_TEMPORARY: "/admins/temporary",
  CHECK_CONFIRMATION_TOKEN: "/admins/confirmation/:confirmationToken",
  VERIFY_ADMIN: "/admins/verify",
  CREATE_ADMIN_COMPLETE: "/admins/create",
  ADMIN_DETAIL: "/admins/:detailId",
  UPDATE_ADMIN: "/admins/:detailId",
};
