import { getColumnTableConvertNullable } from "@app/helpers/util.helper";

export const STATUS_OPTIONS = [
  {
    value: "open",
    label: "未振込",
  },
  {
    value: "paid",
    label: "振込済み",
  },
  {
    value: "failed",
    label: "振込失敗",
  },
  {
    value: "completed",
    label: "振込成功",
  },
];

export const PAYMENT_STATUS = {
  open: "未振込",
  paid: "振込済み",
  failed: "振込失敗",
  completed: "振込成功",
};

export enum StatusType {
  open = "open",
  paid = "paid",
  failed = "failed",
  completed = "completed",
}

export enum StatusConfirmPayment {
  success = "success",
  fail = "fail",
}

export const COLUMNS_TABLE_PAYMENT_HISTORIES = getColumnTableConvertNullable([
  {
    title: "ステータス変更日",
    dataIndex: "update_status_at",
    width: "190px",
  },
  {
    title: "振込完了日",
    dataIndex: "paid_at",
    width: "190px",
  },
  {
    title: "購入 POINT",
    dataIndex: "point",
    width: "120px",
  },
  {
    title: "金額",
    dataIndex: "money",
    width: "90px",
  },
  {
    title: "振込ステータス",
    dataIndex: "status",
    width: "150px",
  },
  {
    title: "ユーザー名",
    dataIndex: "username",
    width: "120px",
  },
  {
    title: "メールアドレス",
    dataIndex: "email",
    ellipsis: true,
    width: "150px",
  },
  {
    title: "電話番号",
    dataIndex: "tel",
    width: "140px",
  },
  {
    title: "所持POINT",
    dataIndex: "total_point",
    width: "120px",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "335px",
  },
]);
