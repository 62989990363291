import { getColumnTableConvertNullable } from "@app/helpers/util.helper";

export const ROLES_TYPE_LIST = {
  ADMIN: "admin",
  ADMIN_NORMAL: "admin_normal",
  ADMIN_SHOP: "admin_shop",
};

export const ROLES_TYPE: Record<string, string> = {
  admin: "管理者",
  admin_normal: "一般管理者",
  admin_shop: "販売者",
};

export const COLUMNS_TABLE_ROLES = getColumnTableConvertNullable([
  {
    title: "ロール名",
    dataIndex: "name",
  },
  {
    title: "ロールタイプ ",
    dataIndex: "type",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "350px",
  },
]);
