import { lazy } from "react";

import BlankLayout from "@app/components/layouts/BlankLayout/BlankLayout";
import { RouteItem } from "@app/types/route.types";

import { AuthPathsEnum } from "../constants/auth.paths";

const LOGIN_SCREEN: RouteItem = {
  id: "AD-0001",
  path: AuthPathsEnum.LOGIN,
  component: lazy(() => import("../screens/LoginScreen/LoginScreen")),
  navigationTitle: "auth.loginTitle",
  layout: BlankLayout,
  isAuthRoute: true,
};

const DEMO_SCREEN: RouteItem = {
  id: "demo",
  path: AuthPathsEnum.HOME,
  component: lazy(() => import("../screens/HomeScreen/HomeScreen")),
  navigationTitle: "auth.loginTitle",
};

const MY_PAGE_SCREEN: RouteItem = {
  id: "my_page",
  path: AuthPathsEnum.MY_PAGE,
  component: lazy(() => import("../screens/MyPageScreen/MyPageScreen")),
};

const FORGOT_PASSWORD_SCREEN: RouteItem = {
  id: "UA-0015",
  path: AuthPathsEnum.FORGOT_PASSWORD,
  component: lazy(
    () => import("../screens/ForgotPasswordScreen/ForgotPasswordScreen")
  ),
  layout: BlankLayout,
  isAuthRoute: true,
};

const FORGOT_PASSWORD_SUCCESS_SCREEN: RouteItem = {
  id: "UA-0016",
  path: AuthPathsEnum.FORGOT_PASSWORD_SUCCESS,
  component: lazy(
    () =>
      import(
        "../screens/ForgotPasswordSuccessScreen/ForgotPasswordSuccessScreen"
      )
  ),
  layout: BlankLayout,
  isAuthRoute: true,
};

const TOKEN_EXPIRATION_SCREEN: RouteItem = {
  id: "UA-0023",
  path: AuthPathsEnum.TOKEN_EXPIRATION,
  layout: BlankLayout,
  component: lazy(
    () => import("../screens/TokenExpirationScreen/TokenExpirationScreen")
  ),
  isAuthRoute: true,
};

const RESET_PASSWORD_SCREEN: RouteItem = {
  id: "UA-0024",
  path: AuthPathsEnum.RESET_PASSWORD,
  component: lazy(
    () => import("../screens/ResetPasswordScreen/ResetPasswordScreen")
  ),
  layout: BlankLayout,
  isAuthRoute: true,
};

export const AUTH_ROUTES = [
  LOGIN_SCREEN,
  FORGOT_PASSWORD_SCREEN,
  FORGOT_PASSWORD_SUCCESS_SCREEN,
  TOKEN_EXPIRATION_SCREEN,
  RESET_PASSWORD_SCREEN,
  MY_PAGE_SCREEN,
];
export const AUTH_ROUTES_PRIVATE = [DEMO_SCREEN];
