import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { AuthPathsEnum } from "@app/features/auth/auth";
import { isNotGranted } from "@app/helpers/permisstion.helper";

interface useGrantedProps {
  screenCode: string;
}

const useGranted = ({ screenCode }: useGrantedProps) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (isNotGranted(screenCode)) {
      navigate(AuthPathsEnum.NOT_FOUND);
    }
  }, [navigate, screenCode]);

  return <></>;
};

export default useGranted;
