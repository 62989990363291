import { createSlice } from "@reduxjs/toolkit";

import { createApiAsyncThunk } from "@app/redux/api.thunk";
import { RequestParams } from "@app/types/api.types";

import api from "../api/shops.api";
import {
  SHOPS_FEATURE_KEY,
  ShopList,
  UpdateShopDisplayOrderRequestBody,
} from "../shops";

type ShopsState = {
  shopList: ShopList | null;
};

const initialState: ShopsState = {
  shopList: null,
};

export const getShopList = createApiAsyncThunk(
  `${SHOPS_FEATURE_KEY}/getShopList`,
  (params: RequestParams) => {
    return api.shopListApi(params);
  }
);

export const deleteShop = createApiAsyncThunk(
  `${SHOPS_FEATURE_KEY}/deleteShop`,
  (shopId: string) => {
    return api.deleteShopApi(shopId);
  }
);

export const createShop = createApiAsyncThunk(
  `${SHOPS_FEATURE_KEY}/createShop`,
  (data: FormData) => {
    return api.createShopApi(data);
  }
);

export const shopDetailById = createApiAsyncThunk(
  `${SHOPS_FEATURE_KEY}/shopDetail`,
  (shopId: string) => {
    return api.shopDetailApi(shopId);
  }
);

export const updateShopDisplayOrder = createApiAsyncThunk(
  `${SHOPS_FEATURE_KEY}/updateShopDisplayOrder`,
  ({ shopId, previous_display_order }: UpdateShopDisplayOrderRequestBody) => {
    return api.updateShopDisplayOrderApi({ shopId, previous_display_order });
  }
);

export const updateShop = createApiAsyncThunk(
  `${SHOPS_FEATURE_KEY}/updateShop`,
  ({ shopId, data }: { shopId: string; data: FormData }) => {
    return api.updateShopApi(shopId, data);
  }
);

const shopSlice = createSlice({
  name: SHOPS_FEATURE_KEY,
  initialState,
  reducers: {
    updateShopList: (state, action) => {
      state.shopList = action.payload;
    },
    updateStatus: (state, action) => {
      const data = action.payload;
      if (state.shopList?.shops) {
        const index = state.shopList?.shops.findIndex(
          item => item.id === data.id
        );
        state.shopList?.shops.splice(index, 1, data);
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getShopList.rejected, state => {
      state.shopList = null;
    });

    builder.addCase(getShopList.fulfilled, (state, action) => {
      state.shopList = action.payload;
    });
  },
});

export const { updateShopList, updateStatus } = shopSlice.actions;

export const shopsReducer = shopSlice.reducer;
