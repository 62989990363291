import { ReactNode, memo } from "react";

import { useMount } from "ahooks";
import { useLoaderData, useNavigate } from "react-router-dom";

import { redirectToLogin } from "@app/helpers/route.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppSelector } from "@app/redux/store";

interface LoaderData {
  isAuthRoute?: boolean;
  isProtectedRoute?: boolean;
}

const ProtectedLayout = ({ children }: { children: ReactNode }) => {
  const { tokenInfo } = useAppSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const loaderData = useLoaderData() as LoaderData;

  useMount(() => {
    const isLogged = !!tokenInfo?.access_token;
    if (isLogged && loaderData?.isAuthRoute) {
      navigate("/");
    }
    if (!isLogged && loaderData?.isProtectedRoute) {
      redirectToLogin();
    }
  });

  return <>{children}</>;
};

export default memo(ProtectedLayout);
