import { memo, useState } from "react";

import { Checkbox, Col, Input, Row } from "antd";
import { serialize } from "object-to-formdata";
import { useNavigate } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import FormUpload from "@app/components/atoms/FormUpload/FormUpload";
import InputIntegerNumber from "@app/components/atoms/InputIntegerNumber/InputIntegerNumber";
import { setFieldErrors } from "@app/helpers/util.helper";
import { requireRules } from "@app/helpers/validations.helper";

import { Banner, BannersPathsEnum } from "../../banners";

interface FormDataBannerProps {
  onSubmit: (data: FormData) => Promise<void>;
  bannerDetail?: Banner;
}

const FormDataBanner = ({ onSubmit, bannerDetail }: FormDataBannerProps) => {
  const [form] = useForm();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = () => {
    setIsSubmitting(true);
    const formValues = form.getFieldsValue();
    const payload = serialize({
      ...formValues,
      is_display: formValues.is_display ? 1 : "",
    });

    onSubmit(payload)
      .then(() => navigate(BannersPathsEnum.LIST_BANNER))
      .catch(err => setFieldErrors({ err, form }))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Form onFinish={handleSubmit} form={form} initialValues={bannerDetail}>
      <Item className="mx-auto mw-600" name="href_url" label="バナーURL">
        <Input maxLength={255} />
      </Item>

      <Row className="mx-auto mw-600">
        <Col span={12}>
          <FormUpload
            rules={bannerDetail ? undefined : requireRules()}
            form={form}
            isUploadImage
            name="pc_image"
            label="バナー画像_PC"
            className="mr-2"
            showRemoveIcon={!bannerDetail}
            required
          />
        </Col>

        <Col span={12}>
          <FormUpload
            rules={bannerDetail ? undefined : requireRules()}
            form={form}
            isUploadImage
            name="sp_image"
            label="バナー画像_SP"
            className="ml-2"
            showRemoveIcon={!bannerDetail}
            required
          />
        </Col>
      </Row>

      <Item
        className="mx-auto mw-600"
        name="is_display"
        label="ステータス"
        valuePropName="checked"
      >
        <Checkbox value={1}>表示</Checkbox>
      </Item>

      <Item className="mx-auto mw-600" name="display_order" label="表示順">
        <InputIntegerNumber min={1} />
      </Item>

      <Row className="mx-auto mw-600">
        <Col span={12}>
          <Row justify="end" className="mr-2">
            <Button
              className="full-width mw-200"
              onClick={() => navigate(BannersPathsEnum.LIST_BANNER)}
            >
              キャンセル
            </Button>
          </Row>
        </Col>

        <Col span={12}>
          <Row justify="start" className="ml-2">
            <Button
              className="full-width mw-200"
              loading={isSubmitting}
              htmlType="submit"
              type="primary"
            >
              保存
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default memo(FormDataBanner);
