import { AxiosResponse } from "axios";

import { api } from "@app/api/api";
import { DEFAULT_PER_PAGE } from "@app/constants/constants";

import { RequestUserParams, UsersEndpointEnum } from "../users";

const usersListApi = (params?: RequestUserParams): Promise<AxiosResponse> => {
  const url = UsersEndpointEnum.USER;
  return api.get(url, { params: { ...params, limit: DEFAULT_PER_PAGE } });
};

const userDetailApi = (userId: string): Promise<AxiosResponse> => {
  const url = UsersEndpointEnum.DETAIL_USER.replace(":userId", userId);
  return api.get(url);
};

const userAddPointApi = (
  userId: string,
  point: number
): Promise<AxiosResponse> => {
  const url = UsersEndpointEnum.UPDATE_POINT_USER.replace(":userId", userId);
  return api.post(url, { point });
};

const userResetPointApi = (userId: string): Promise<AxiosResponse> => {
  const url = UsersEndpointEnum.DELETE_POINT_USER.replace(":userId", userId);
  return api.post(url);
};

const deleteUserApi = (userId: string): Promise<AxiosResponse> => {
  const url = UsersEndpointEnum.DETAIL_USER.replace(":userId", userId);
  return api.delete(url);
};

const userApi = {
  usersListApi,
  userDetailApi,
  userAddPointApi,
  userResetPointApi,
  deleteUserApi,
};

export default userApi;
