import { createSlice } from "@reduxjs/toolkit";

import { createApiAsyncThunk } from "@app/redux/api.thunk";

import api from "../api/card-masters.api";
import {
  ListCardRaresRequest,
  CARD_MASTERS_FEATURE_KEY,
  ListCardRares,
  CardMasterRequest,
  ListCardMasters,
} from "../card-masters";

type CardImagesState = {
  cardRareList: ListCardRares | null;
  cardMastersList: ListCardMasters | null;
};

const initialState: CardImagesState = {
  cardRareList: null,
  cardMastersList: null,
};

export const getCardRaresList = createApiAsyncThunk(
  `${CARD_MASTERS_FEATURE_KEY}/getCardRaresList`,
  (params: ListCardRaresRequest) => {
    return api.getCardRaresListApi(params);
  }
);

export const deleteCardRare = createApiAsyncThunk(
  `${CARD_MASTERS_FEATURE_KEY}/deleteCardRare`,
  (id: string) => {
    return api.deleteCardRaresApi(id);
  }
);

export const editCardRare = createApiAsyncThunk(
  `${CARD_MASTERS_FEATURE_KEY}/editCardRare`,
  ({ id, data }: { id: string; data: FormData }) => {
    return api.editCardRaresApi(id, data);
  }
);

export const createCardRare = createApiAsyncThunk(
  `${CARD_MASTERS_FEATURE_KEY}/createCardRare`,
  (data: FormData) => {
    return api.createCardRaresApi(data);
  }
);

export const getCardMastersList = createApiAsyncThunk(
  `${CARD_MASTERS_FEATURE_KEY}/getListCardMasters`,
  (params: CardMasterRequest) => {
    return api.getListCardMastersApi(params);
  }
);
const cardMastersSlice = createSlice({
  name: CARD_MASTERS_FEATURE_KEY,
  initialState,
  reducers: {
    resetCardRaresStore: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getCardRaresList.fulfilled, (state, action) => {
      state.cardRareList = action.payload;
    });
    builder.addCase(getCardMastersList.fulfilled, (state, action) => {
      state.cardMastersList = action.payload;
    });
  },
});

export const cardMastersReducer = cardMastersSlice.reducer;
export const { resetCardRaresStore } = cardMastersSlice.actions;
