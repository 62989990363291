import { lazy } from "react";

import { RouteItem } from "@app/types/route.types";

import { PacksPathsEnum } from "../constants/packs.paths";

const PACKS_LIST_SCREEN: RouteItem = {
  id: "packs",
  path: PacksPathsEnum.PACKS_LIST,
  component: lazy(() => import("../screens/PacksListScreen/PacksListScreen")),
};

const CREATE_PACK_SCREEN: RouteItem = {
  id: "create_packs",
  path: PacksPathsEnum.CREATE_PACK,
  component: lazy(() => import("../screens/CreatePackScreen/CreatePackScreen")),
};

const PACK_CSV_SCREEN: RouteItem = {
  id: "pack_csv",
  path: PacksPathsEnum.PACK_CSV,
  component: lazy(() => import("../screens/PackCsvScreen/PackCsvScreen")),
};

const EDIT_PACK_SCREEN: RouteItem = {
  id: "edit_packs",
  path: PacksPathsEnum.EDIT_PACK,
  component: lazy(() => import("../screens/EditPackScreen/EditPackScreen")),
};

const CARD_REGISTRATION_SCREEN: RouteItem = {
  id: "card_registration",
  path: PacksPathsEnum.CARD_REGISTRATION,
  component: lazy(
    () => import("../screens/CardRegistrationScreen/CardRegistrationScreen")
  ),
};
const CREATE_BULK_BOXES_SCREEN: RouteItem = {
  id: "create_bulk_boxes",
  path: PacksPathsEnum.CREATE_BULK_BOXES,
  component: lazy(() => import("../screens/BulkBoxesScreen/BulkBoxesScreen")),
};

const EDIT_BULK_BOXES_SCREEN: RouteItem = {
  id: "edit_bulk_boxes",
  path: PacksPathsEnum.EDIT_BULK_BOXES,
  component: lazy(() => import("../screens/BulkBoxesScreen/BulkBoxesScreen")),
};

export const PACKS_ROUTES = [
  PACKS_LIST_SCREEN,
  CREATE_PACK_SCREEN,
  EDIT_PACK_SCREEN,
  CARD_REGISTRATION_SCREEN,
  CREATE_BULK_BOXES_SCREEN,
  EDIT_BULK_BOXES_SCREEN,
  PACK_CSV_SCREEN,
];
