import { useEffect, useState } from "react";

import { Typography } from "antd";
import { useParams } from "react-router-dom";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { LIST_CODE_SCREEN } from "@app/constants/constants";
import useGranted from "@app/hooks/useGranted/useGranted";
import { useAppDispatch } from "@app/redux/store";

import FormDataShop from "../../components/FormDataShop/FormDataShop";
import { ShopDetail, shopDetailById, updateShop } from "../../shops";

const EditShopScreen = () => {
  const dispatch = useAppDispatch();
  const { shopId } = useParams<{ shopId: string }>();
  useGranted({ screenCode: LIST_CODE_SCREEN.SHOP_EDIT });

  const [shopDetail, setShopDetail] = useState<ShopDetail | undefined>(
    undefined
  );

  const handleUpdateShop = (data: FormData) => {
    return dispatch(updateShop({ shopId: String(shopId), data })).unwrap();
  };

  useEffect(() => {
    if (shopId) {
      dispatch(shopDetailById(shopId))
        .unwrap()
        .then(data => setShopDetail(data));
    }
  }, [dispatch, shopId]);

  return (
    <>
      <Typography.Title level={3}>企業編集</Typography.Title>
      {shopDetail ? (
        <FormDataShop
          isModeEdit
          shopDetail={shopDetail}
          onSubmit={data => handleUpdateShop(data)}
        />
      ) : (
        <LoadingSpinner isFitContent />
      )}
    </>
  );
};

export default EditShopScreen;
