import { AuthPathsEnum } from "@app/features/auth/auth";
import { router } from "@app/routes/Router";
import { RouteGroup, RouteItem } from "@app/types/route.types";

export const flatten = (items: Array<RouteItem | RouteGroup>) => {
  const flat: RouteItem[] = [];

  items.forEach(item => {
    if (Array.isArray(item.nestedRoutes)) {
      flat.push(...flatten(item.nestedRoutes));
    } else {
      flat.push(item as RouteItem);
    }
  });

  return flat;
};

export const redirectToLogin = () => {
  const redirect = window.location.pathname + window.location.search;
  return router.navigate(AuthPathsEnum.LOGIN, { state: { redirect } });
};
