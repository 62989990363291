import { Typography } from "antd";

import { LIST_CODE_SCREEN } from "@app/constants/constants";
import useGranted from "@app/hooks/useGranted/useGranted";
import { useAppDispatch } from "@app/redux/store";

import FormDataShop from "../../components/FormDataShop/FormDataShop";
import { createShop } from "../../shops";

const CreateShopScreen = () => {
  const dispatch = useAppDispatch();
  useGranted({ screenCode: LIST_CODE_SCREEN.SHOP_CREATE });
  const handleCreateShop = (data: FormData) => {
    return dispatch(createShop(data)).unwrap();
  };

  return (
    <>
      <Typography.Title level={3}>企業新規作成</Typography.Title>
      <FormDataShop onSubmit={data => handleCreateShop(data)} />
    </>
  );
};

export default CreateShopScreen;
