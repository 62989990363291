import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import cx from "classnames";

import styles from "./LoadingSpinner.module.scss";

interface LoadingSpinnerProps {
  isFitContent?: boolean;
  className?: string;
}

function LoadingSpinner({ isFitContent, className }: LoadingSpinnerProps) {
  const antIcon = <Loading3QuartersOutlined className="font-33" spin />;
  return (
    <div
      className={cx(styles.container, className, {
        [styles.isFitContent]: isFitContent,
      })}
    >
      <Spin indicator={antIcon} />
    </div>
  );
}

export default LoadingSpinner;
