import { LabelsPathsEnum } from "@app/features/labels/labels";
import { RouteItem } from "@app/types/route.types";

import CreateLabelScreen from "../screens/CreateLabelScreen/CreateLabelScreen";
import EditLabelScreen from "../screens/EditLabelScreen/EditLabelScreen";
import LabelListScreen from "../screens/LabelListScreen/LabelListScreen";

const LABEL_LIST_SCREEN: RouteItem = {
  id: "AD-0031",
  path: LabelsPathsEnum.LIST_LABEL,
  component: LabelListScreen,
};

const CREATE_LABEL_SCREEN: RouteItem = {
  id: "AD-0032-1",
  path: LabelsPathsEnum.CREATE_LABEL,
  component: CreateLabelScreen,
};

const EDIT_LABEL_SCREEN: RouteItem = {
  id: "AD-0032-2",
  path: LabelsPathsEnum.EDIT_LABEL,
  component: EditLabelScreen,
};

export const LABELS_ROUTES = [
  LABEL_LIST_SCREEN,
  CREATE_LABEL_SCREEN,
  EDIT_LABEL_SCREEN,
];
