import axios, { AxiosResponse } from "axios";

import { api } from "@app/api/api";
import { DEFAULT_PER_PAGE } from "@app/constants/constants";

import { PacksEndpoints } from "../constants/packs.endpoints";
import {
  CreateBulkBoxRequestBody,
  GetAutoSplitBoxRequestParam,
  GetPacksRequestParam,
} from "../types/packs.types";

export const getPacksListApi = (
  params?: GetPacksRequestParam
): Promise<AxiosResponse> => {
  return api.get(PacksEndpoints.GET_PACKS_LIST, {
    params: { ...params, limit: DEFAULT_PER_PAGE },
  });
};

export const deletePackApi = (packId: number): Promise<AxiosResponse> => {
  return api.delete(
    PacksEndpoints.DELETE_PACK.replace(":packId", String(packId))
  );
};

export const publishPackApi = (packId: number): Promise<AxiosResponse> => {
  return api.post(
    PacksEndpoints.PACK_PUBLISH.replace(":packId", String(packId)),
    { action: "publish" }
  );
};

export const createPackApi = ({
  shopId,
  data,
}: {
  shopId: string;
  data: FormData;
}): Promise<AxiosResponse> => {
  return api.post(PacksEndpoints.CREATE_PACK.replace(":shopId", shopId), data, {
    transformRequest: () => {
      return data;
    },
  });
};

export const postPackExportApi = (packId: string): Promise<AxiosResponse> => {
  return api.post(PacksEndpoints.PACK_EXPORT.replace(":packId", packId));
};

export const getDownloadCsvFileApi = (
  fileUrl: string
): Promise<AxiosResponse> => {
  return axios.get(fileUrl);
};

export const editPackApi = ({
  packId,
  data,
}: {
  packId: string;
  data: FormData;
}): Promise<AxiosResponse> => {
  return api.post(PacksEndpoints.EDIT_PACK.replace(":packId", packId), data, {
    params: {
      _method: "PATCH",
    },
    transformRequest: () => {
      return data;
    },
  });
};

export const getPackDetailApi = (packId: string): Promise<AxiosResponse> => {
  return api.get(PacksEndpoints.PACK_DETAIL.replace(":packId", packId));
};

export const getBoxDetailApi = (packId: string): Promise<AxiosResponse> => {
  return api.get(PacksEndpoints.GET_BOX_DETAIL.replace(":packId", packId));
};

export const getAutoSplitBoxApi = ({
  packId,
  params,
}: {
  packId: string;
  params: GetAutoSplitBoxRequestParam;
}): Promise<AxiosResponse> => {
  return api.get(PacksEndpoints.GET_AUTO_SLPIT_BOX.replace(":packId", packId), {
    params,
  });
};

export const createBulkBoxApi = ({
  packId,
  data,
}: {
  packId: string;
  data: CreateBulkBoxRequestBody;
}): Promise<AxiosResponse> => {
  return api.post(
    PacksEndpoints.CREATE_BULK_BOX.replace(":packId", packId),
    data
  );
};

export const packsApi = {
  getPacksListApi,
  deletePackApi,
  publishPackApi,
  createPackApi,
  getPackDetailApi,
  editPackApi,
  getBoxDetailApi,
  getAutoSplitBoxApi,
  createBulkBoxApi,
  postPackExportApi,
  getDownloadCsvFileApi,
};
