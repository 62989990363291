import { memo } from "react";

import { Typography } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { LIST_CODE_SCREEN } from "@app/constants/constants";
import { AuthPathsEnum } from "@app/features/auth/auth";
import { editLabel } from "@app/features/labels/labels";
import useGranted from "@app/hooks/useGranted/useGranted";
import { useAppDispatch } from "@app/redux/store";

import FormDataLabel from "../../components/FormDataLabel";

const EditLabelScreen = () => {
  const dispatch = useAppDispatch();
  const labelDetail = useLocation().state.label;
  const navigate = useNavigate();
  useGranted({ screenCode: LIST_CODE_SCREEN.LABEL_EDIT });

  const handleUpdateLabel = (data: FormData) => {
    return dispatch(
      editLabel({ labelId: String(labelDetail.id), data })
    ).unwrap();
  };

  if (!labelDetail) {
    navigate(AuthPathsEnum.NOT_FOUND);
  }

  return (
    <>
      {labelDetail ? (
        <>
          <Typography.Title level={3}>ラベル編集画面</Typography.Title>
          <FormDataLabel
            labelDetail={labelDetail}
            onSubmit={data => handleUpdateLabel(data)}
          />
        </>
      ) : (
        <LoadingSpinner isFitContent />
      )}
    </>
  );
};

export default memo(EditLabelScreen);
