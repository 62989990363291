import { createSlice } from "@reduxjs/toolkit";

import { createApiAsyncThunk } from "@app/redux/api.thunk";
import { RequestParams } from "@app/types/api.types";

import api from "../api/cards.api";
import { CardList, CARDS_FEATURE_KEY } from "../cards";

type CardsState = {
  cardList: CardList | null;
};

const initialState: CardsState = {
  cardList: null,
};

type GetCardListRequestParams = {
  tel: string | undefined;
  statuses: string[];
  user_id: string;
};

export const getCardList = createApiAsyncThunk(
  `${CARDS_FEATURE_KEY}/getCardList`,
  (params: RequestParams & GetCardListRequestParams) => {
    return api.cardListApi(params);
  }
);

export const updateCardStatus = createApiAsyncThunk(
  `${CARDS_FEATURE_KEY}/updateCardStatus`,
  (params: { id: string }) => {
    return api.updateCardStatusApi(params);
  }
);

const cardSlice = createSlice({
  name: CARDS_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCardList.fulfilled, (state, action) => {
      state.cardList = action.payload;
    });
  },
});

export const cardsReducer = cardSlice.reducer;
