import { memo } from "react";

import { Pagination, PaginationProps } from "antd";
import cx from "classnames";
import { useSearchParams } from "react-router-dom";

import { PAGINATION_SIZE } from "@app/constants/constants";

import styles from "./PaginationCustom.module.scss";

function PaginationCustom({ total, current, ...rest }: PaginationProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChangePage = (page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  if (Number(current) > Math.ceil(Number(total) / PAGINATION_SIZE))
    return <></>;

  return (
    <Pagination
      total={total}
      current={current}
      pageSize={PAGINATION_SIZE}
      className={cx(styles.pagination, "text-center")}
      showSizeChanger={false}
      onChange={page => handleChangePage(page)}
      {...rest}
    />
  );
}

export default memo(PaginationCustom);
