import { getColumnTableConvertNullable } from "@app/helpers/util.helper";

export const COLUMNS_TABLE_PACKS = getColumnTableConvertNullable([
  {
    title: "企業名",
    dataIndex: "companyName",
  },
  {
    title: "ルームタイトル",
    dataIndex: "roomTitle",
  },
  {
    title: "ラベル",
    dataIndex: "label",
  },
  {
    title: "総口数",
    dataIndex: "totalCard",
    width: "90px",
  },
  {
    title: "ポイント数",
    dataIndex: "numberPoint",
    width: "120px",
  },
  {
    title: "残数",
    dataIndex: "numberCardRemain",
    width: "90px",
  },
  {
    title: "ステータス",
    dataIndex: "status",
    width: "170px",
  },
  {
    title: "販売開始時間",
    dataIndex: "startSaleAt",
    width: "190px",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "400px",
  },
]);

export const CARD_REGISTRATION_TABLE_COLUMNS = getColumnTableConvertNullable([
  {
    title: "企業名",
    dataIndex: "shopName",
  },
  {
    title: "ルームタイトル",
    dataIndex: "title",
  },
  {
    title: "総口数",
    dataIndex: "total_card",
    width: "90px",
  },
  {
    title: "入力状況",
    dataIndex: "status",
    width: "170px",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "250px",
  },
]);

export const BULK_BOXES_TABLE_COLUMNS = [
  {
    title: "番号順",
    dataIndex: "number",
    width: "100px",
  },
  {
    title: "大当たり",
    dataIndex: "number_big_hit",
    width: "100px",
    editable: true,
  },
  {
    title: "中当たり",
    dataIndex: "number_medium_hit",
    width: "100px",
    editable: true,
  },
  {
    title: "小当たり",
    dataIndex: "number_small_hit",
    width: "100px",
    editable: true,
  },
  {
    title: "ハズレ",
    dataIndex: "number_fail",
    width: "100px",
    editable: true,
  },
  {
    title: "総数",
    dataIndex: "number_total",
    width: "100px",
  },
];

export enum PACKS_STATUS_TYPE {
  ON_SALE = "on_sale",
  SOLD_OUT = "sold_out",
  CREATED = "created",
  UPLOAD_CSV_IN_PROGRESS = "upload_csv_in_progress",
  UPLOAD_CSV_COMPLETED = "upload_csv_completed",
  UPLOAD_CSV_FAILED = "upload_csv_failed",
  GACHA_IN_PROGRESS = "gacha_in_progress",
  GACHA_COMPLETED = "gacha_completed",
  GACHA_FAILED = "gacha_failed",
}

export const PACKS_STATUS: Record<string, string> = {
  on_sale: "開催中",
  sold_out: "開催終了",
  created: "作成済ルーム",
  upload_csv_in_progress: "アップロード実行中",
  upload_csv_completed: "アップロード終了",
  upload_csv_failed: "アップロード失敗",
  gacha_in_progress: "ガチャ実行中",
  gacha_completed: "ガチャ終了",
  gacha_failed: "ガチャ失敗",
};

export const ENTRY_PACKS_STATUS: Record<string, string> = {
  [PACKS_STATUS_TYPE.UPLOAD_CSV_COMPLETED]: "未入力",
  [PACKS_STATUS_TYPE.GACHA_COMPLETED]: "入力済",
  [PACKS_STATUS_TYPE.GACHA_FAILED]: "ガチャ失敗",
};

export const BOX_NUMBER_OPTION = [5, 10, 15, 20, 25, 30, 40, 50, 100];

export enum EXPORT_STATUS_TYPE {
  CREATED = "created",
  IN_PROGRESS = "in_progress",
  FAILED = "failed",
  COMPLETED = "completed",
}

export const EXPORT_STATUS: Record<string, string> = {
  [EXPORT_STATUS_TYPE.CREATED]: "作成済",
  [EXPORT_STATUS_TYPE.IN_PROGRESS]: "実行中",
  [EXPORT_STATUS_TYPE.FAILED]: "失敗",
  [EXPORT_STATUS_TYPE.COMPLETED]: "終了",
};

export const TIME_BATCH_PROCESSING = 2000;
