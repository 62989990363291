import { Typography } from "antd";
import { useNavigate } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";

function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="full-height flex-center">
      <div className="text-center">
        <Typography className="font-30 font-weight-bold">
          Page not found ❗️
        </Typography>
        <Button className="mt-10" onClick={() => navigate("/")} type="primary">
          Back to home
        </Button>
      </div>
    </div>
  );
}

export default NotFound;
