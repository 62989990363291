import { useEffect, useState } from "react";

import { UploadOutlined } from "@ant-design/icons";
import { ConfigProvider, FormItemProps, Modal, Upload, UploadFile } from "antd";
import { RcFile } from "antd/es/upload";
import { FormInstance } from "antd/lib/form";

import { getBase64 } from "@app/helpers/file.helper";

import antdThemeButton from "./antd-theme-button.json";
import Button from "../Button/Button";
import { Item } from "../Form/Form";

interface FormUploadProps extends FormItemProps {
  isUploadImage?: boolean;
  form?: FormInstance;
  showRemoveIcon?: boolean;
  disabledUpload?: boolean;
  reload?: () => void;
}

const FormUpload = ({
  isUploadImage,
  form,
  name,
  showRemoveIcon = true,
  disabledUpload = false,
  reload,
  ...rest
}: FormUploadProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [dataImageBase64, setDataImageBase64] = useState<string | undefined>(
    undefined
  );

  // get default image
  useEffect(() => {
    const imageUrl = form?.getFieldValue(`${name}_url`);
    if (imageUrl) {
      setFileList([
        {
          uid: "1",
          name: "image.png",
          status: "done",
          url: imageUrl,
        },
      ]);
    }
    return () => {
      setFileList([]);
    };
  }, [form, name]);

  return (
    <>
      <Item
        {...rest}
        key={fileList.length}
        getValueFromEvent={e => {
          if (e.file.status === "removed") {
            return undefined;
          }
          return e.file.originFileObj;
        }}
        name={name}
      >
        <Upload
          listType="picture"
          accept={isUploadImage ? ".jpg, .png, .jpeg, .gif, .webp" : ".csv"}
          customRequest={({ onSuccess }: any) => {
            onSuccess("ok");
          }}
          onChange={() => (reload ? reload() : undefined)}
          maxCount={1}
          defaultFileList={fileList}
          onRemove={() => setFileList([])}
          disabled={disabledUpload}
          onPreview={async data => {
            if (!isUploadImage) return;
            if (data.originFileObj) {
              data.preview = await getBase64(data.originFileObj as RcFile);
            }
            setDataImageBase64(data.url ?? data.preview);
          }}
          showUploadList={{ showRemoveIcon }}
        >
          <ConfigProvider theme={antdThemeButton}>
            <Button type="primary" icon={<UploadOutlined />}>
              ファイルを選択
            </Button>
          </ConfigProvider>
        </Upload>
      </Item>
      <Modal
        open={!!dataImageBase64}
        footer={null}
        centered
        onCancel={() => setDataImageBase64(undefined)}
        width="700px"
      >
        <div className="p-5">
          <img
            width="100%"
            style={{
              maxHeight: "80vh",
              objectFit: "contain",
            }}
            src={dataImageBase64}
            alt=""
          />
        </div>
      </Modal>
    </>
  );
};

export default FormUpload;
