/* eslint-disable no-useless-escape */
import { Rule } from "antd/es/form";

import { ERROR_MESSAGES } from "@app/constants/message.constants";

export const TEXT_ENGLISH_HALFWIDTH_REGEX = /^[a-zA-Z0-9]+$/;
export const PASSWORD_REGEX =
  /^[A-Za-z\d@$!%*#?&\/\\\"{}|;<>[\]`+~=_,^()-.\:\']*$/g;
export const PHONE_REGEX = /^(?=.*[\d])[\d]{12}$/g;
export const REGEX_NUMBER = /^[0-9]+$/;
export const REGEX_HIRAGANA = /^[ぁ-ん]+$/;
export const REGEX_EMAIL = /^[\w-+\\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

export const formatMessageError = (
  message: string,
  list: Record<string, string>
) => {
  let newMessage = message;
  const keys = Object.keys(list);
  keys.forEach(key => {
    newMessage = newMessage.replace(`%${key}%`, list[key]);
  });
  return newMessage;
};

export const requireRules = (): Rule[] => [
  {
    required: true,
    message: "この項目は必須です。",
  },
];

export const emailRules: Rule[] = [
  {
    required: true,
    message: "メールアドレスは、必ず指定してください。",
  },
  {
    min: 8,
    max: 255,
    message: formatMessageError(ERROR_MESSAGES.UA_24, {
      attribute: "メールアドレ",
    }),
  },
  {
    pattern: REGEX_EMAIL,
    transform: value => value.trim(),
    message: "メールアドレスは、有効なメールアドレス形式で指定してください。",
  },
];

export const passwordRules: Rule[] = [
  {
    required: true,
    whitespace: true,
    message: "パスワードは、必ず指定してください。",
  },
  {
    min: 8,
    max: 50,
    message: formatMessageError(ERROR_MESSAGES.UA_24, {
      attribute: "パスワード",
    }),
  },
  {
    pattern: PASSWORD_REGEX,
    message: "パスワードの形式は半角英数字記号でお願いします。",
  },
];

export const phoneRules = () => [
  {
    min: 10,
    message: "10文字以上で入力してください。",
  } as Rule,
  {
    max: 13,
    message: "13文字以下で入力してください。",
  } as Rule,
];
