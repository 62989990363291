import { memo, useState } from "react";

import { Input, Row, Col, Checkbox } from "antd";
import { serialize } from "object-to-formdata";
import { useNavigate } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import FormUpload from "@app/components/atoms/FormUpload/FormUpload";
import { setFieldErrors } from "@app/helpers/util.helper";
import {
  emailRules,
  phoneRules,
  requireRules,
} from "@app/helpers/validations.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppSelector } from "@app/redux/store";

import { ROLES_TYPE_LIST } from "../../../roles/constants/roles.constants";
import { ShopPathsEnum, ShopDetail } from "../../shops";

interface FormDataShopProps {
  onSubmit: (data: FormData) => Promise<void>;
  shopDetail?: ShopDetail;
  isModeEdit?: boolean;
}

const FormDataShop = ({
  onSubmit,
  shopDetail,
  isModeEdit = false,
}: FormDataShopProps) => {
  const [form] = useForm();
  const navigate = useNavigate();
  const { currentUser } = useAppSelector((state: RootState) => state.auth);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmitForm = () => {
    setIsSubmitting(true);
    const formInit = form.getFieldsValue();
    onSubmit(
      serialize({
        ...formInit,
        is_display: formInit.is_display || !isModeEdit ? 1 : 0,
      })
    )
      .then(() => navigate(ShopPathsEnum.SHOP_LIST))
      .catch(err => setFieldErrors({ err, form }))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      <Form
        labelAlign="left"
        initialValues={shopDetail}
        onFinish={handleSubmitForm}
        form={form}
        className="mb-10"
      >
        <Item
          className="mx-auto mw-600"
          name="name"
          label="会社名"
          rules={requireRules()}
        >
          <Input size="large" maxLength={255} />
        </Item>

        <Item
          className="mx-auto mw-600"
          name="address"
          label="住所"
          rules={requireRules()}
        >
          <Input.TextArea
            rows={4}
            size="large"
            style={{ height: 210, resize: "none" }}
            maxLength={255}
          />
        </Item>

        <Item
          className="mx-auto mw-600"
          name="tel"
          label="電話番号"
          extra={
            <p className="text-gray-40 my-0 font-13">
              次の形式で電話番号を入力してください：XXX-XXXX-XXXX
            </p>
          }
          rules={[...requireRules(), ...phoneRules()]}
        >
          <Input size="large" />
        </Item>

        <Item
          className="mx-auto mw-600"
          name="email"
          label="メールアドレス"
          labelCol={{ flex: 1 }}
          rules={emailRules}
        >
          <Input size="large" maxLength={255} />
        </Item>

        <Item
          className="mx-auto mw-600"
          label="サムネイル"
          labelCol={{ flex: 1 }}
          required
        >
          <FormUpload
            rules={shopDetail ? undefined : requireRules()}
            showRemoveIcon={!shopDetail}
            form={form}
            isUploadImage
            name="image"
          />
        </Item>

        <Item
          className="mx-auto mw-600"
          name="description"
          label="会社説明"
          rules={requireRules()}
        >
          <Input.TextArea
            rows={4}
            size="large"
            maxLength={255}
            style={{ height: 210, resize: "none" }}
          />
        </Item>

        {isModeEdit &&
          currentUser?.role.type !== ROLES_TYPE_LIST.ADMIN_SHOP && (
            <Item
              className="mx-auto mw-600"
              name="is_display"
              label="ステータス"
              valuePropName="checked"
            >
              <Checkbox>表示</Checkbox>
            </Item>
          )}

        <Row gutter={6} justify="center" className="mt-15">
          <Col span={12}>
            <Row justify="end">
              <Button
                className="full-width mw-200"
                disabled={isSubmitting}
                onClick={() => {
                  navigate(ShopPathsEnum.SHOP_LIST);
                }}
              >
                キャンセル
              </Button>
            </Row>
          </Col>

          <Col span={12}>
            <Row justify="start">
              <Button
                className="full-width mw-200"
                type="primary"
                htmlType="submit"
                disabled={isSubmitting}
              >
                保存
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default memo(FormDataShop);
