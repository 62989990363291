import { getColumnTableConvertNullable } from "@app/helpers/util.helper";

export const CARD_RARES_TABLE_COLUMNS = getColumnTableConvertNullable([
  {
    title: "カードのレア度",
    dataIndex: "name",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: 200,
  },
]);

export const COLUMNS_TABLE_CARD_MASTERS = getColumnTableConvertNullable([
  {
    title: "コード",
    dataIndex: "code",
    width: 150,
  },
  {
    title: "タイプ",
    dataIndex: "types",
    width: 150,
  },
  {
    title: "名前",
    dataIndex: "name",
    width: 350,
  },
  {
    title: "型番",
    dataIndex: "model_number",
    width: 200,
  },
  {
    title: "レア",
    dataIndex: "card_rare",
    width: 150,
  },
  {
    title: "画像パス",
    dataIndex: "image_path",
  },
]);

export const OPTIONS_CARD_TYPE = [
  { value: "last_one", label: "ラストワン" },
  { value: "big_hit", label: "大当たり" },
  { value: "medium_hit", label: "中当たり" },
  { value: "small_hit", label: "小当たり" },
  { value: "fail", label: "ハズレ" },
];
