import { UserAddress, UserDetail } from "../users";

export const getUserAddress = (user: UserAddress): string => {
  if (!user) return "";

  return `${user?.prefecture?.name ?? ""}${user?.city ?? ""}${
    user?.address ?? ""
  }${user?.apartment_name_room_number ?? ""}`;
};

export const generateUserAddress = (userDetail: UserDetail | undefined) => {
  const addressesDefault =
    userDetail?.user_addresses.find(item => item.is_default) ??
    ({} as UserAddress);

  const addresses = userDetail?.user_addresses
    .filter(item => !item.is_default)
    .map(item => ({
      ...item,
      addressDetail: getUserAddress(item),
      userName: `${item.firstname ?? ""} ${item.lastname ?? ""}`,
    }));

  return {
    ...userDetail,
    addresses,
    addressesDefault: {
      ...addressesDefault,
      addressDetail: getUserAddress(addressesDefault),
    },
  };
};
