import {
  ADMINS_ROUTES,
  ADMINS_ROUTES_PUBLIC,
} from "@app/features/admins/admins";
import { AUTH_ROUTES, AUTH_ROUTES_PRIVATE } from "@app/features/auth/auth";
import { BANNERS_ROUTES } from "@app/features/banners/banners";
import { CARD_IMAGES_ROUTES } from "@app/features/card-images/card-images";
import { CARD_MASTERS_ROUTES } from "@app/features/card-masters/card-masters";
import { CARDS_ROUTES } from "@app/features/cards/cards";
import { LABELS_ROUTES } from "@app/features/labels/labels";
import { PACKS_ROUTES } from "@app/features/packs/packs";
import { PAYMENT_HISTORIES_ROUTES } from "@app/features/payment-histories/payment-histories";
import { ROLES_ROUTES } from "@app/features/roles/roles";
import { SETTINGS_ROUTES } from "@app/features/settings/settings";
import { SHOPS_ROUTES } from "@app/features/shops/shops";
import { USERS_ROUTES } from "@app/features/users/users";

export const ROOT_ROUTE = "/";

export const PUBLIC_LIST = [...AUTH_ROUTES, ...ADMINS_ROUTES_PUBLIC];
export const PRIVATE_LIST = [
  ...AUTH_ROUTES_PRIVATE,
  ...PACKS_ROUTES,
  ...SHOPS_ROUTES,
  ...SETTINGS_ROUTES,
  ...CARDS_ROUTES,
  ...USERS_ROUTES,
  ...PAYMENT_HISTORIES_ROUTES,
  ...BANNERS_ROUTES,
  ...CARD_IMAGES_ROUTES,
  ...ROLES_ROUTES,
  ...ADMINS_ROUTES,
  ...LABELS_ROUTES,
  ...CARD_MASTERS_ROUTES,
];
