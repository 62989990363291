import { lazy } from "react";

import { RouteItem } from "@app/types/route.types";

import { PaymentHistoriesPathsEnum } from "../constants/payment-histories.paths";

const PAYMENT_HISTORIES_LIST_SCREEN: RouteItem = {
  id: "payment-histories",
  path: PaymentHistoriesPathsEnum.PAYMENT_HISTORIES_LIST,
  component: lazy(
    () =>
      import("../screens/PaymentHistoriesListScreen/PaymentHistoriesListScreen")
  ),
};

export const PAYMENT_HISTORIES_ROUTES = [PAYMENT_HISTORIES_LIST_SCREEN];
