import { createSlice } from "@reduxjs/toolkit";

import { createApiAsyncThunk } from "@app/redux/api.thunk";
import { RequestParams } from "@app/types/api.types";

import { settingsApi } from "../api/settings.api";
import { SETTINGS_FEATURE_KEY } from "../constants/settings.keys";
import { SettingsState } from "../types/settings.types";

const initialState: SettingsState = {
  settingsList: null,
};

export const getSettingsList = createApiAsyncThunk(
  `${SETTINGS_FEATURE_KEY}/getSettingList`,
  (params?: RequestParams) => {
    return settingsApi.getSettingsListApi(params);
  }
);

export const updateSetting = createApiAsyncThunk(
  `${SETTINGS_FEATURE_KEY}/updateSetting`,
  (params: { id: number; body: { value: string } }) => {
    return settingsApi.updateSettingApi(params);
  }
);

const settingsSlice = createSlice({
  name: SETTINGS_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getSettingsList.pending, state => {
      state.settingsList = null;
    });
    builder.addCase(getSettingsList.fulfilled, (state, action) => {
      state.settingsList = action.payload;
    });
    builder.addCase(getSettingsList.rejected, state => {
      state.settingsList = null;
    });
  },
});

export const settingsReducer = settingsSlice.reducer;
