import { memo, useEffect, useMemo, useState } from "react";

import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Table, Typography } from "antd";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import Row from "@app/components/atoms/CustomRow/CustomRow";
import { LIST_CODE_SCREEN } from "@app/constants/constants";
import {
  deleteLabel,
  getLabelList,
  updateLabelDisplayOrder,
  updateLabelList,
  LabelsPathsEnum,
} from "@app/features/labels/labels";
import { isNotGranted } from "@app/helpers/permisstion.helper";
import { getMessageErrors } from "@app/helpers/util.helper";
import useGranted from "@app/hooks/useGranted/useGranted";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import { COLUMNS_TABLE_LABELS } from "../../constants/labels.constants";

const LabelListScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useGranted({ screenCode: LIST_CODE_SCREEN.LABEL_LIST });

  const [isLoading, setIsLoading] = useState(true);
  const { labelList } = useSelector((state: RootState) => state.labels);
  const [triggerReload, setTriggerReload] = useState(false);

  const handleDeleteLabel = (id: number) => {
    return dispatch(deleteLabel({ id }))
      .unwrap()
      .then(() => setTriggerReload(!triggerReload))
      .catch(err => {
        getMessageErrors(err);
      });
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over || active.id === over?.id) return;

    const activeIndex =
      labelList?.labels?.findIndex(i => i.id === active.id) ?? 0;
    const overIndex = labelList?.labels?.findIndex(i => i.id === over?.id) ?? 0;
    const labelListUpdate = arrayMove(
      [...(labelList?.labels ?? [])],
      activeIndex,
      overIndex
    );

    const activeOder = get(
      labelListUpdate,
      `${overIndex - 1}.display_order`,
      0
    );

    dispatch(
      updateLabelList({
        ...labelList,
        labels: labelListUpdate,
      })
    );

    setIsLoading(true);
    dispatch(
      updateLabelDisplayOrder({
        labelId: String(active.id),
        previous_display_order: activeOder,
      })
    )
      .unwrap()
      .catch(err => {
        getMessageErrors(err);
      })
      .finally(() => {
        setIsLoading(false);
        setTriggerReload(!triggerReload);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(getLabelList({}))
      .unwrap()
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, triggerReload, navigate]);

  const labelDataSource = useMemo(() => {
    return labelList?.labels.map(item => ({
      ...item,
      action: (
        <div className="flex-align-center">
          <Button
            className="ml-3"
            disabled={isNotGranted(LIST_CODE_SCREEN.LABEL_EDIT)}
            type="primary"
            onClick={() =>
              navigate(LabelsPathsEnum.EDIT_LABEL, {
                state: {
                  label: item,
                },
              })
            }
          >
            編集
          </Button>
          <Button
            className="ml-3"
            type="primary"
            disabled={isNotGranted(LIST_CODE_SCREEN.LABEL_DELETE)}
            danger
            isShowConfirm
            onSubmit={() => handleDeleteLabel(item.id)}
          >
            削除
          </Button>
        </div>
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelList?.labels]);

  return (
    <div>
      <Typography.Title level={3}>ラベル一覧</Typography.Title>
      <Button
        className="mt-4"
        type="primary"
        width="300px"
        disabled={isNotGranted(LIST_CODE_SCREEN.LABEL_CREATE)}
        to={LabelsPathsEnum.CREATE_LABEL}
      >
        新規追加
      </Button>

      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={labelDataSource ? labelDataSource?.map(i => i.id) : []}
          strategy={verticalListSortingStrategy}
          disabled={isNotGranted(LIST_CODE_SCREEN.LABEL_SORT)}
        >
          <Table
            className="py-10"
            components={{
              body: {
                row: Row,
              },
            }}
            bordered
            rowKey="id"
            loading={isLoading}
            dataSource={labelDataSource}
            columns={[
              {
                key: "sort",
                fixed: "left",
                width: "50px",
              },
              ...COLUMNS_TABLE_LABELS,
            ]}
            pagination={false}
          />
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default memo(LabelListScreen);
