import { ElementType } from "react";

import { Route } from "react-router-dom";

import DefaultLayout from "@app/components/layouts/DefaultLayout/DefaultLayout";
import {
  RouteComponent,
  RouteItem,
  RouteWrapperConfig,
} from "@app/types/route.types";

import ProtectedLayout from "./components/ProtectedLayout/ProtectedLayout";
import authenticatedLoader from "./loaders/authenticated.loader";

export const routeWrapper = (
  { id, path, layout, component, isAuthRoute }: RouteItem,
  { isProtectedRoute }: RouteWrapperConfig | undefined = {}
) => {
  const Layout = (layout ?? DefaultLayout) as ElementType;
  const Component = component as RouteComponent;

  return (
    <Route
      key={id}
      path={path}
      loader={({ params }) =>
        authenticatedLoader({ params, isAuthRoute, isProtectedRoute })
      }
      element={
        <ProtectedLayout>
          <Layout>
            <Component />
          </Layout>
        </ProtectedLayout>
      }
    />
  );
};
