import { getColumnTableConvertNullable } from "@app/helpers/util.helper";

export const SHOP_LIST_COLUMN = getColumnTableConvertNullable([
  {
    title: "企業名",
    dataIndex: "name",
    key: "name",
    width: 300,
    fixed: "left",
  },
  {
    title: "住所",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "電話番号",
    dataIndex: "tel",
    key: "tel",
    width: 140,
  },
  {
    title: "メールアドレス",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "ステータス",
    dataIndex: "status",
    key: "status",
  },
]);

export const MESSAGE_ERRORS = {
  EMAIL_VALID: "emailの入力は必須です。",
};

export enum SHOP_STATUS {
  SHOW = "表示",
  HIDE = "非表示",
}
