import { get } from "lodash";

import store from "@app/redux/store";

export const isNotGranted = (screenCode: string) => {
  const { currentUser } = store.getState().auth;
  if (!currentUser) {
    return true;
  }
  if (currentUser.role?.type === "admin") {
    return false;
  }
  return !get(currentUser, `role.permissions.${screenCode}`);
};
