/* eslint-disable consistent-return */
// eslint-disable-next-line filenames/match-regex
import { isValidElement } from "react";

import { FormInstance, Tooltip } from "antd";
import { ColumnProps } from "antd/es/table";
import dayjs from "dayjs";
import { get, isArray, isString } from "lodash";

import { openNotification } from "@app/components/molecules/Notification/notification";
import { ERROR_MESSAGES } from "@app/constants/message.constants";
import { ShopDetail } from "@app/features/shops/shops";
import { ErrorsResponse } from "@app/types/api.types";

/**
 * Check if a string looks like an external URL
 */
export const isURL = (str: string) => {
  return /http|www/.test(str);
};

/**
 * A promise to delay an async function
 * @param ms how many milliseconds to wait
 */
export const delay = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const getInitials = (name: string, maxChar: number) => {
  return name
    .split(/\s/)
    .map(word => word[0])
    .join("")
    .substr(0, maxChar)
    .toUpperCase();
};

/**
 * Scroll to top of screen smoothly,
 * or fallback to instant scroll to top
 */
export const scrollToTop = () => {
  try {
    // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  } catch (error) {
    // fallback for older browsers
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }
};

export const getMessages = (arrMessCodes: string[]) => {
  let str = "";
  arrMessCodes.forEach(code => {
    str += code;
  });
  return str;
};

export const checkInvalidForm = ({ form }: { form: FormInstance }) => {
  return !!form.getFieldsError().filter(({ errors }) => errors.length).length;
};

export const setFieldErrors = ({
  err,
  form,
}: {
  err: ErrorsResponse;
  form: FormInstance;
}) => {
  return form.setFields(
    Object.keys(err).map(key => {
      if (!form.getFieldInstance(key) && !!err[key].length) {
        openNotification({ type: "error", message: getMessages(err[key]) });
      }
      return {
        name: key,
        errors: [getMessages(err[key])],
      };
    })
  );
};

export const getMessageErrors = (err: ErrorsResponse) => {
  if (isString(err)) {
    if (err === "network.unknown") {
      return;
    }
    return openNotification({
      type: "error",
      message: get(ERROR_MESSAGES, err) ?? err,
    });
  }
  if (isArray(err)) {
    return openNotification({ type: "error", message: getMessages(err) });
  }
  return Object.values(err).forEach(value => {
    openNotification({ type: "error", message: getMessages(value) });
  });
};

export const getTimeUTC = (time?: string, format = "YYYY/MM/DD HH:mm") => {
  // format = "" -> time to request
  if (!time) return null;
  return dayjs(time).utc(true).local().format(format);
};

export const getValueDisplay = (value?: string | null) =>
  [null, undefined, ""].includes(value) ? "-" : value;

export const getColumnTableConvertNullable = (columns: ColumnProps<any>[]) => {
  return columns.map(item => ({
    render: (content: string | null) => {
      if (isValidElement(content)) return content;
      const valueDisplay = getValueDisplay(content);
      return (
        <Tooltip placement="topLeft" title={valueDisplay}>
          <span>{valueDisplay}</span>
        </Tooltip>
      );
    },
    ...item,
    ellipsis: true,
  }));
};

export const handleSendMailto = async (email: string) => {
  const a = document.createElement("a");
  a.setAttribute("href", `mailto:${email}`);
  a.click();
  a.remove();
};

export const getAvailableShops = (shopList?: ShopDetail[]) => {
  return shopList
    ?.filter(item => item.is_display)
    .map(shop => ({
      label: shop.name,
      value: shop.id,
    }));
};
