import { createSlice } from "@reduxjs/toolkit";

import { createApiAsyncThunk } from "@app/redux/api.thunk";

import api from "../api/labels.api";
import {
  LABELS_FEATURE_KEY,
  LabelList,
  GetLabelListRequestParams,
  UpdateLabelDisplayOrderRequestBody,
} from "../labels";

type LabelsState = {
  labelList: LabelList | null;
};

const initialState: LabelsState = {
  labelList: null,
};

export const getLabelList = createApiAsyncThunk(
  `${LABELS_FEATURE_KEY}/getLabelList`,
  (params: GetLabelListRequestParams) => {
    return api.labelListApi(params);
  }
);

export const deleteLabel = createApiAsyncThunk(
  `${LABELS_FEATURE_KEY}/deleteLabel`,
  (params: { id: number }) => {
    return api.deleteLabelApi(params);
  }
);

export const createLabel = createApiAsyncThunk(
  `${LABELS_FEATURE_KEY}/createLabel`,
  (data: FormData) => {
    return api.createLabelApi(data);
  }
);

export const editLabel = createApiAsyncThunk(
  `${LABELS_FEATURE_KEY}/editLabel`,
  ({ labelId, data }: { labelId: string; data: FormData }) => {
    return api.editLabelApi({ labelId, data });
  }
);

export const updateLabelDisplayOrder = createApiAsyncThunk(
  `${LABELS_FEATURE_KEY}/updateLabelDisplayOrder`,
  ({ labelId, previous_display_order }: UpdateLabelDisplayOrderRequestBody) => {
    return api.updateLabelDisplayOrderApi({ labelId, previous_display_order });
  }
);

const labelSlice = createSlice({
  name: LABELS_FEATURE_KEY,
  initialState,
  reducers: {
    updateLabelList: (state, action) => {
      state.labelList = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getLabelList.fulfilled, (state, action) => {
      state.labelList = action.payload;
    });
  },
});

export const { updateLabelList } = labelSlice.actions;

export const labelsReducer = labelSlice.reducer;
