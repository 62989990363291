import { getColumnTableConvertNullable } from "@app/helpers/util.helper";

export const COLUMNS_TABLE_CARDS = getColumnTableConvertNullable([
  {
    title: "ステータス変更時間 ",
    dataIndex: "updatedAt",
    width: 200,
  },
  {
    title: "ステータス",
    dataIndex: "status",
    width: 150,
  },
  {
    title: "対象企業",
    dataIndex: "shopName",
    ellipsis: true,
    width: 100,
  },
  {
    title: "対象企業メールアドレス",
    dataIndex: "shopMail",
    width: 220,
  },
  {
    title: "対象企業電話番号",
    dataIndex: "shopTel",
    width: 180,
  },
  {
    title: "パック名",
    dataIndex: "packName",
    ellipsis: true,
    width: 150,
  },
  {
    title: "発送先",
    dataIndex: "shipAddress",
    ellipsis: true,
    width: 150,
  },
  {
    title: "発送の電話番号",
    dataIndex: "phoneShip",
    width: 150,
  },
  {
    title: "対象カード",
    dataIndex: "cardName",
    width: 180,
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "action",
    width: 180,
  },
]);

export const CARD_STATUS = {
  ON_SALE: "on_sale",
  SOLD: "sold",
  WAIT_FOR_SEND: "wait_for_send",
  SENDING: "sending",
  SENT: "sent",
  EXCHANGED: "exchanged",
  CREATED: "created",
};

export const CARD_STATUS_JA = {
  [CARD_STATUS.SOLD]: "販売済み",
  [CARD_STATUS.WAIT_FOR_SEND]: "未発送",
  [CARD_STATUS.SENT]: "発送済み",
};
