import { createSlice } from "@reduxjs/toolkit";

import { createApiAsyncThunk } from "@app/redux/api.thunk";

import { packsApi } from "../api/packs.api";
import { PACKS_FEATURE_KEY } from "../constants/packs.keys";
import {
  CreateBulkBoxRequestBody,
  GetAutoSplitBoxRequestParam,
  GetPacksRequestParam,
  PacksState,
} from "../types/packs.types";

const initialState: PacksState = {
  packsList: null,
};

export const getPacksList = createApiAsyncThunk(
  `${PACKS_FEATURE_KEY}/getPacksList`,
  (params?: GetPacksRequestParam) => {
    return packsApi.getPacksListApi(params);
  }
);

export const deletePack = createApiAsyncThunk(
  `${PACKS_FEATURE_KEY}/deletePack`,
  (packId: number) => {
    return packsApi.deletePackApi(packId);
  }
);

export const publishPack = createApiAsyncThunk(
  `${PACKS_FEATURE_KEY}/publishPack`,
  (packId: number) => {
    return packsApi.publishPackApi(packId);
  }
);

export const createPack = createApiAsyncThunk(
  `${PACKS_FEATURE_KEY}/createPack`,
  ({ shopId, data }: { shopId: string; data: FormData }) => {
    return packsApi.createPackApi({ shopId, data });
  }
);

export const getPackDetail = createApiAsyncThunk(
  `${PACKS_FEATURE_KEY}/getPackDetail`,
  (packId: string) => {
    return packsApi.getPackDetailApi(packId);
  }
);

export const editPack = createApiAsyncThunk(
  `${PACKS_FEATURE_KEY}/editPack`,
  ({ packId, data }: { packId: string; data: FormData }) => {
    return packsApi.editPackApi({ packId, data });
  }
);

export const getBoxDetail = createApiAsyncThunk(
  `${PACKS_FEATURE_KEY}/getBoxDetail`,
  (packId: string) => {
    return packsApi.getBoxDetailApi(packId);
  }
);

export const getAutoSplitBox = createApiAsyncThunk(
  `${PACKS_FEATURE_KEY}/getAutoSplitBox`,
  ({
    packId,
    params,
  }: {
    packId: string;
    params: GetAutoSplitBoxRequestParam;
  }) => {
    return packsApi.getAutoSplitBoxApi({ packId, params });
  }
);

export const createBulkBox = createApiAsyncThunk(
  `${PACKS_FEATURE_KEY}/createBulkBox`,
  ({ packId, data }: { packId: string; data: CreateBulkBoxRequestBody }) => {
    return packsApi.createBulkBoxApi({ packId, data });
  }
);

export const postPackExport = createApiAsyncThunk(
  `${PACKS_FEATURE_KEY}/postPackExport`,
  async (packId: string) => {
    return packsApi.postPackExportApi(packId);
  }
);

export const getDownloadCsvFile = createApiAsyncThunk<Blob, string>(
  `${PACKS_FEATURE_KEY}/getDownloadCsvFile`,
  async fileUrl => {
    const response = await packsApi.getDownloadCsvFileApi(fileUrl);
    return response;
  }
);

const packsSlice = createSlice({
  name: PACKS_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getPacksList.fulfilled, (state, action) => {
      state.packsList = action.payload;
    });
    builder.addCase(getPacksList.rejected, state => {
      state.packsList = null;
    });
  },
});

export const packsReducer = packsSlice.reducer;
