import { memo, useState } from "react";

import {
  ButtonProps as AntdButtonProps,
  Button as AntdButton,
  Modal,
} from "antd";
import cx from "classnames";
import { Link, LinkProps } from "react-router-dom";

import { isURL } from "@app/helpers/util.helper";

import styles from "./Button.module.scss";

interface ButtonProps extends Omit<AntdButtonProps, "href"> {
  to?: LinkProps["to"];
  noPadding?: boolean;
  className?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  onSubmit?: () => Promise<any>;
  isShowConfirm?: boolean;
  disabled?: boolean;
  title?: string;
  textButton?: string;
}

function Button({
  to,
  className,
  noPadding,
  width,
  height,
  borderRadius,
  children,
  onSubmit,
  isShowConfirm,
  disabled,
  title = `選択したユーザー情報を削除します。\nよろしいですか？`,
  ...rest
}: ButtonProps) {
  const isExternalLink = typeof to === "string" && isURL(to);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);

  const onClickButton = () => {
    if (onSubmit) {
      setIsSubmitting(true);
      onSubmit()
        .then(() => setIsOpenModalConfirm(false))
        .finally(() => setIsSubmitting(false));
    }
  };

  const buttonContent = (
    <AntdButton
      className={cx(styles.button, className, {
        [styles.noPadding]: noPadding,
      })}
      style={{ width, height, borderRadius }}
      {...(isExternalLink && {
        href: to as string,
        target: "_blank",
        rel: "noopener noreferrer",
      })}
      onClick={() => {
        isShowConfirm ? setIsOpenModalConfirm(true) : onClickButton();
      }}
      disabled={isSubmitting || disabled}
      {...rest}
    >
      {children}
    </AntdButton>
  );

  // Only wrap in react router link, if internal link
  if (!isExternalLink && to) {
    return <Link to={to}>{buttonContent}</Link>;
  }

  return (
    <>
      {buttonContent}
      {isOpenModalConfirm && (
        <Modal
          centered
          width={600}
          okButtonProps={{ disabled: isSubmitting, danger: true }}
          open={isOpenModalConfirm}
          cancelText="キャンセル"
          onCancel={() => setIsOpenModalConfirm(false)}
          onOk={onClickButton}
          title={<p className="text-center pre-wrap">{title}</p>}
        />
      )}
    </>
  );
}

export default memo(Button);
