import { Typography } from "antd";

import { LIST_CODE_SCREEN } from "@app/constants/constants";
import useGranted from "@app/hooks/useGranted/useGranted";
import { useAppDispatch } from "@app/redux/store";

import { createBanner } from "../../banners";
import FormDataBanner from "../../components/FormDataBanner/FormDataBanner";

const CreateBannerScreen = () => {
  const dispatch = useAppDispatch();
  useGranted({ screenCode: LIST_CODE_SCREEN.BANNER_CREATE });

  const handleCreateBanner = (data: FormData) => {
    return dispatch(createBanner(data)).unwrap();
  };

  return (
    <div>
      <Typography.Title level={3}>バナー新規作成</Typography.Title>
      <FormDataBanner onSubmit={data => handleCreateBanner(data)} />
    </div>
  );
};

export default CreateBannerScreen;
