/**
 * API response status codes enum
 */
export enum ApiStatusCodes {
  SUCCESS = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  FORBIDDEN = 403,
}

export const GRANT_TYPE = {
  REFRESH_TOKEN: "refresh_token",
};

export const AXIOS_ERROR_CODE = {
  TIME_OUT: "ECONNABORTED",
};

export const TIME_OUT_API = 20000;
