import { createSlice } from "@reduxjs/toolkit";

import { createApiAsyncThunk } from "@app/redux/api.thunk";

import rolesApi from "../api/roles.api";
import { ROLES_FEATURE_KEY } from "../constants/roles.keys";
import {
  PermissionUpdateRequest,
  RoleCreateRequest,
  RolesRequestParams,
  RolesState,
} from "../types/roles.types";

const initialState: RolesState = {
  listRole: null,
  permissions: null,
};

export const getRoleList = createApiAsyncThunk(
  `${ROLES_FEATURE_KEY}/getRoleList`,
  (params?: RolesRequestParams) => {
    return rolesApi.getRolesApi(params);
  }
);

export const deleteRole = createApiAsyncThunk(
  `${ROLES_FEATURE_KEY}/deleteRole`,
  ({ roleId }: { roleId: string }) => {
    return rolesApi.deleteRoleApi({ roleId });
  }
);

export const getPermissions = createApiAsyncThunk(
  `${ROLES_FEATURE_KEY}/getPermissions`,
  () => {
    return rolesApi.getPermissionsApi();
  }
);

export const getRoleDetail = createApiAsyncThunk(
  `${ROLES_FEATURE_KEY}/getRoleDetail`,
  (roleId: string) => {
    return rolesApi.getRoleDetailApi(roleId);
  }
);

export const createRole = createApiAsyncThunk(
  `${ROLES_FEATURE_KEY}/createRole`,
  (data: RoleCreateRequest) => {
    return rolesApi.createRoleApi(data);
  }
);

export const updateRole = createApiAsyncThunk(
  `${ROLES_FEATURE_KEY}/updateRole`,
  ({ roleId, data }: { roleId: string; data: RoleCreateRequest }) => {
    return rolesApi.updateRoleApi({ roleId, data });
  }
);

export const updatePermission = createApiAsyncThunk(
  `${ROLES_FEATURE_KEY}/updatePermission`,
  ({ data }: { data: PermissionUpdateRequest }) => {
    return rolesApi.updatePermissionApi({ data });
  }
);

const rolesSlice = createSlice({
  name: ROLES_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getRoleList.pending, state => {
      state.listRole = null;
    });
    builder.addCase(getRoleList.fulfilled, (state, action) => {
      state.listRole = action.payload;
    });
    builder.addCase(getRoleList.rejected, state => {
      state.listRole = null;
    });

    builder.addCase(getPermissions.pending, state => {
      state.permissions = null;
    });
    builder.addCase(getPermissions.fulfilled, (state, action) => {
      state.permissions = action.payload.permissions;
    });
    builder.addCase(getPermissions.rejected, state => {
      state.permissions = null;
    });
  },
});

export const rolesReducer = rolesSlice.reducer;
