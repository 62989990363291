import { getColumnTableConvertNullable } from "@app/helpers/util.helper";

export const COLUMNS_TABLE_ADMIN = getColumnTableConvertNullable([
  {
    title: "ユーザー名",
    dataIndex: "username",
    width: 120,
  },
  {
    title: "メールアドレス",
    dataIndex: "email",
    width: 150,
  },
  {
    title: "ロール名",
    dataIndex: "role_name",
    width: 120,
  },
  {
    title: "ロールタイプ",
    dataIndex: "role_type",
    width: 150,
  },
  {
    title: "所属店舗",
    dataIndex: "shop_name",
    width: 120,
  },
  {
    title: "ステータス",
    dataIndex: "active",
    width: 130,
  },
  {
    title: "操作",
    dataIndex: "actions",
    width: 430,
  },
]);

export const AdminActivesDisplay: Record<string, string> = {
  "0": "非アクティブ",
  "1": "アクティブ",
};

export const AdminAccountAuth = {
  VALID: "valid",
  EXPIRED: "expired",
  INVALID: "invalid",
};
