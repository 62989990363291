import { memo, useEffect, useMemo, useState } from "react";

import { Col, Row, Table, Typography, Input, Select } from "antd";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import PaginationCustom from "@app/components/atoms/PaginationCustom/PaginationCustom";
import { DEFAULT_PER_PAGE, LIST_CODE_SCREEN } from "@app/constants/constants";
import useGranted from "@app/hooks/useGranted/useGranted";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import {
  COLUMNS_TABLE_CARD_MASTERS,
  CardMastersPathsEnum,
  OPTIONS_CARD_TYPE,
  getCardMastersList,
  getCardRaresList,
} from "../../card-masters";

const ListCardMastersScreen = () => {
  const dispatch = useAppDispatch();
  const [search] = useSearchParams();
  const { cardMastersList } = useAppSelector(state => state.cardMasters);
  const { cardRareList } = useAppSelector(state => state.cardMasters);
  const navigate = useNavigate();
  useGranted({ screenCode: LIST_CODE_SCREEN.CARD_MASTER_LIST });

  const [isLoading, setIsLoading] = useState(false);

  const [form] = useForm();

  const page = Number(search.get("page") ?? 1);
  const codeParam = search.get("code") ?? undefined;
  const typesParam = search.get("types");
  const nameParam = search.get("name") ?? undefined;
  const modelNumberParam = search.get("model_number") ?? undefined;
  const cardRareIdsParam = search.get("card_rare_ids");

  const handleSubmit = () => {
    const { code, name, types, model_number, card_rare_ids } =
      form.getFieldsValue();
    const params = {
      types: types ? [types] : undefined,
      code: code ?? undefined,
      name: name ?? undefined,
      model_number: model_number ?? undefined,
      card_rare_ids: card_rare_ids ? [card_rare_ids] : undefined,
    };
    navigate({
      pathname: CardMastersPathsEnum.LIST_CARD_MASTERS,
      search: `?${createSearchParams(JSON.parse(JSON.stringify(params)))}`,
    });
  };

  useEffect(() => {
    if (!cardRareList?.card_rares.length) {
      dispatch(
        getCardRaresList({
          use_page: "false",
        })
      );
    }
  }, [cardRareList?.card_rares.length, dispatch]);

  useEffect(() => {
    const params = {
      types: typesParam ? [typesParam] : undefined,
      code: codeParam,
      name: nameParam,
      model_number: modelNumberParam,
      card_rare_ids: cardRareIdsParam ? [cardRareIdsParam] : undefined,
    };

    setIsLoading(true);
    dispatch(
      getCardMastersList({
        ...params,
        page,
        limit: DEFAULT_PER_PAGE,
        use_page: "true",
      })
    )
      .unwrap()
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    typesParam,
    codeParam,
    modelNumberParam,
    nameParam,
    cardRareIdsParam,
    dispatch,
    page,
  ]);

  const optionCardRares = useMemo(() => {
    return cardRareList?.card_rares.map(item => ({
      value: item.id,
      label: item.name,
    }));
  }, [cardRareList?.card_rares]);

  const cardMastersDataSource = useMemo(() => {
    return cardMastersList?.card_masters.map(item => ({
      ...item,
      types: item.type,
      key: item.id,
      card_rare: item.card_rare.name,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardMastersList?.card_masters]);

  return (
    <>
      <Typography.Title level={3}>マスターカード一覧</Typography.Title>

      <Form form={form} onFinish={handleSubmit}>
        <Row gutter={12}>
          <Col span={4}>
            <Item name="code" label="コード" initialValue={codeParam}>
              <Input size="large" allowClear />
            </Item>
          </Col>
          <Col span={4}>
            <Item name="types" label="タイプ" initialValue={typesParam}>
              <Select allowClear options={OPTIONS_CARD_TYPE} />
            </Item>
          </Col>
          <Col span={4}>
            <Item name="name" label="名前" initialValue={nameParam}>
              <Input size="large" allowClear />
            </Item>
          </Col>
          <Col span={4}>
            <Item
              name="model_number"
              label="型番"
              initialValue={modelNumberParam}
            >
              <Input size="large" allowClear />
            </Item>
          </Col>
          <Col span={4}>
            <Item
              name="card_rare_ids"
              label="レア"
              initialValue={cardRareIdsParam}
            >
              <Select
                disabled={!cardRareList?.card_rares.length}
                allowClear
                options={optionCardRares}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={4}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              htmlType="submit"
              disabled={isLoading}
            >
              検索
            </Button>
          </Col>
        </Row>
      </Form>

      <Table
        className="mt-5"
        bordered
        pagination={false}
        scroll={{ x: 1000 }}
        loading={isLoading}
        dataSource={cardMastersDataSource}
        columns={COLUMNS_TABLE_CARD_MASTERS}
      />
      <div className="mt-8 pb-5 text-center">
        <PaginationCustom
          current={page}
          total={cardMastersList?.page_info?.total_count}
        />
      </div>
    </>
  );
};

export default memo(ListCardMastersScreen);
