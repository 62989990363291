import { createSlice } from "@reduxjs/toolkit";

import { createApiAsyncThunk } from "@app/redux/api.thunk";

import userApi from "../api/users.api";
import { RequestUserParams, USERS_FEATURE_KEY, UserList } from "../users";

type UsersState = {
  userList: UserList | null;
};

const initialState: UsersState = {
  userList: null,
};

export const getUserList = createApiAsyncThunk(
  `${USERS_FEATURE_KEY}/getUserList`,
  async (params?: RequestUserParams) => {
    return userApi.usersListApi(params);
  }
);

export const getUserDetail = createApiAsyncThunk(
  `${USERS_FEATURE_KEY}/getUserDetail`,
  async (userId: string) => {
    return userApi.userDetailApi(userId);
  }
);

export const addPoint = createApiAsyncThunk(
  `${USERS_FEATURE_KEY}/addPoint`,
  async ({ userId, point }: { userId: string; point: number }) => {
    return userApi.userAddPointApi(userId, point);
  }
);

export const resetPoint = createApiAsyncThunk(
  `${USERS_FEATURE_KEY}/resetPoint`,
  async (userId: string) => {
    return userApi.userResetPointApi(userId);
  }
);

export const deleteUser = createApiAsyncThunk(
  `${USERS_FEATURE_KEY}/deleteUser`,
  async (userId: string) => {
    return userApi.deleteUserApi(userId);
  }
);

const UserSlice = createSlice({
  name: USERS_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getUserList.pending, state => {
      state.userList = null;
    });

    builder.addCase(getUserList.fulfilled, (state, action) => {
      state.userList = action.payload;
    });
  },
});

export const usersReducer = UserSlice.reducer;
