import { memo } from "react";

import { Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { LIST_CODE_SCREEN } from "@app/constants/constants";
import { AuthPathsEnum } from "@app/features/auth/auth";
import useGranted from "@app/hooks/useGranted/useGranted";
import { useAppDispatch } from "@app/redux/store";

import { editCardRare } from "../../card-masters";
import FormDataCardRare from "../../components/FormDataCardRare";

const EditLabelScreen = () => {
  const dispatch = useAppDispatch();
  const cardRareDetail = useLocation().state.cardRare;
  const navigate = useNavigate();
  useGranted({ screenCode: LIST_CODE_SCREEN.CARD_RARE_EDIT });

  const handleUpdateCardRare = (data: FormData) => {
    return dispatch(
      editCardRare({ id: String(cardRareDetail.id), data })
    ).unwrap();
  };

  if (!cardRareDetail) {
    navigate(AuthPathsEnum.NOT_FOUND);
  }

  return (
    <>
      {cardRareDetail ? (
        <>
          <Typography.Title level={3}>カードのレア度編集</Typography.Title>
          <FormDataCardRare
            cardRareDetail={cardRareDetail}
            onSubmit={data => handleUpdateCardRare(data)}
          />
        </>
      ) : (
        <LoadingSpinner isFitContent />
      )}
    </>
  );
};

export default memo(EditLabelScreen);
