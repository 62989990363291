import { lazy } from "react";

import BlankLayout from "@app/components/layouts/BlankLayout/BlankLayout";
import { RouteItem } from "@app/types/route.types";

import { AdminsPathsEnum } from "../constants/admins.paths";

const LIST_ADMIN_SCREEN: RouteItem = {
  id: "ADMIN_LIST",
  path: AdminsPathsEnum.ADMIN_LIST,
  component: lazy(() => import("../screens/ListAdminScreen/ListAdminScreen")),
};

const VERIFY_ADMIN_SCREEN: RouteItem = {
  id: "VERIFY_ACCOUNT",
  path: AdminsPathsEnum.ADMIN_VERIFY_TEMPORARY,
  component: lazy(
    () => import("../screens/VerifyAdminScreen/VerifyAdminScreen")
  ),
  layout: BlankLayout,
};

const VERIFY_RESEND_ADMIN_SCREEN: RouteItem = {
  id: "VERIFY_RESEND_ACCOUNT",
  path: AdminsPathsEnum.ADMIN_VERIFY_RESEND,
  component: lazy(
    () => import("../screens/VerifyAdminScreen/VerifyAdminScreen")
  ),
  layout: BlankLayout,
};

const VERIFY_ADMIN_SUCCESS_SCREEN: RouteItem = {
  id: "VERIFY_ACCOUNT_SUCCESS",
  path: AdminsPathsEnum.ADMIN_VERIFY_SUCCESS,
  component: lazy(
    () => import("../screens/VerifyAdminSuccessScreen/VerifyAdminSuccessScreen")
  ),
  layout: BlankLayout,
};

export const ADMINS_ROUTES = [LIST_ADMIN_SCREEN];
export const ADMINS_ROUTES_PUBLIC = [
  VERIFY_ADMIN_SCREEN,
  VERIFY_RESEND_ADMIN_SCREEN,
  VERIFY_ADMIN_SUCCESS_SCREEN,
];
