import { createSlice } from "@reduxjs/toolkit";

import { createApiAsyncThunk } from "@app/redux/api.thunk";

import api from "../api/banners.api";
import {
  BANNERS_FEATURE_KEY,
  BannerList,
  GetBannerListRequestParams,
} from "../banners";

type BannersState = {
  bannerList: BannerList | null;
};

const initialState: BannersState = {
  bannerList: null,
};

export const getBannerList = createApiAsyncThunk(
  `${BANNERS_FEATURE_KEY}/getBannerList`,
  (params: GetBannerListRequestParams) => {
    return api.bannerListApi(params);
  }
);

export const deleteBanner = createApiAsyncThunk(
  `${BANNERS_FEATURE_KEY}/deleteBanner`,
  (params: { id: number }) => {
    return api.deleteBannerApi(params);
  }
);

export const createBanner = createApiAsyncThunk(
  `${BANNERS_FEATURE_KEY}/createBanner`,
  (data: FormData) => {
    return api.createBannerApi(data);
  }
);

export const editBanner = createApiAsyncThunk(
  `${BANNERS_FEATURE_KEY}/editBanner`,
  ({ bannerId, data }: { bannerId: string; data: FormData }) => {
    return api.editBannerApi({ bannerId, data });
  }
);

const bannerSlice = createSlice({
  name: BANNERS_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getBannerList.fulfilled, (state, action) => {
      state.bannerList = action.payload;
    });
  },
});

export const bannersReducer = bannerSlice.reducer;
