import { AxiosResponse } from "axios";

import { api } from "@app/api/api";
import { DEFAULT_PER_PAGE } from "@app/constants/constants";

import {
  AdminAccountCompleteParams,
  AdminAccountTemporaryParams,
  ListAdminRequestParams,
  UpdateAdminDetail,
  adminsEndpoints,
} from "../admins";

const getAdminListApi = (
  params?: ListAdminRequestParams
): Promise<AxiosResponse> => {
  return api.get(adminsEndpoints.GET_ALL, {
    params: { ...params, limit: DEFAULT_PER_PAGE },
  });
};

const resendVerificationEmailApi = (email: string): Promise<AxiosResponse> => {
  return api.post(adminsEndpoints.RESEND_EMAIL, { email });
};

const deleteAdminAccountApi = (adminId: string): Promise<AxiosResponse> => {
  return api.delete(adminsEndpoints.MODIFY_ADMIN.replace(":adminId", adminId));
};

const createAdminAccountTemporaryApi = (
  data: AdminAccountTemporaryParams
): Promise<AxiosResponse> => {
  return api.post(adminsEndpoints.CREATE_TEMPORARY, data);
};

const checkConfirmationTokenAdminApi = (
  confirmationToken: string
): Promise<AxiosResponse> => {
  return api.get(
    adminsEndpoints.CHECK_CONFIRMATION_TOKEN.replace(
      ":confirmationToken",
      confirmationToken
    )
  );
};

const createAdminAccountCompleteApi = (
  data: AdminAccountCompleteParams
): Promise<AxiosResponse> => {
  return api.post(adminsEndpoints.CREATE_ADMIN_COMPLETE, data);
};

const verifyAdminApi = (confirmationToken: string): Promise<AxiosResponse> => {
  return api.post(adminsEndpoints.VERIFY_ADMIN, {
    confirmation_token: confirmationToken,
  });
};

const getAdminDetailApi = (detailId: string): Promise<AxiosResponse> => {
  return api.get(adminsEndpoints.ADMIN_DETAIL.replace(":detailId", detailId));
};

const updateAdminApi = ({
  detailId,
  data,
}: {
  detailId: string;
  data: UpdateAdminDetail;
}): Promise<AxiosResponse> => {
  return api.patch(
    adminsEndpoints.UPDATE_ADMIN.replace(":detailId", detailId),
    data
  );
};

adminsEndpoints;

const adminsApi = {
  getAdminListApi,
  resendVerificationEmailApi,
  deleteAdminAccountApi,
  createAdminAccountTemporaryApi,
  checkConfirmationTokenAdminApi,
  createAdminAccountCompleteApi,
  verifyAdminApi,
  getAdminDetailApi,
  updateAdminApi,
};
export default adminsApi;
