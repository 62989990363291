export enum AuthPathsEnum {
  LOGIN = "/login",
  NOT_FOUND = "/404",
  MY_PAGE = "/my-page",
  HOME = "/",
  TOKEN_EXPIRATION = "/token-expiration",
  FORGOT_PASSWORD = "/forgot-password",
  FORGOT_PASSWORD_SUCCESS = "/forgot-password/success",
  RESET_PASSWORD = "/reset-password/:token",
}
