import { getColumnTableConvertNullable } from "@app/helpers/util.helper";

export const COLUMNS_TABLE_LABELS = getColumnTableConvertNullable([
  {
    title: "ラベル名称",
    dataIndex: "name",
  },
  {
    title: "ラベル画像",
    dataIndex: "image_path",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: 200,
  },
]);
