import { RouteItem } from "@app/types/route.types";

import { UserPathEnum } from "../constants/users.paths";
import ListUserScreen from "../screens/ListUserScreen/ListUserScreen";
import PreviewUserScreen from "../screens/PreviewUserScreen/PreviewUserScreen";

const LIST_USER_SCREEN: RouteItem = {
  id: "users",
  path: UserPathEnum.LIST_USER,
  component: ListUserScreen,
};

const PREVIEW_USER_SCREEN: RouteItem = {
  id: "detail_user",
  path: UserPathEnum.USER_DETAIL,
  component: PreviewUserScreen,
};

export const USERS_ROUTES = [LIST_USER_SCREEN, PREVIEW_USER_SCREEN];
