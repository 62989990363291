export const PacksEndpoints = {
  GET_PACKS_LIST: "/packs",
  DELETE_PACK: "/packs/:packId",
  EDIT_PACK: "/packs/:packId",
  PACK_PUBLISH: "/packs/:packId/publish",
  PACK_DETAIL: "/packs/:packId",
  CREATE_PACK: "shops/:shopId/packs",
  GET_BOX_DETAIL: "/packs/:packId/boxes",
  GET_AUTO_SLPIT_BOX: "/packs/:packId/boxes/auto-split",
  CREATE_BULK_BOX: "/packs/:packId/boxes/bulk-create",
  PACK_EXPORT: "/packs/:packId/export",
};
