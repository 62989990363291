import { combineReducers } from "@reduxjs/toolkit";

import { ADMINS_FEATURE_KEY, adminsReducer } from "@app/features/admins/admins";
import { authReducer, AUTH_FEATURE_KEY } from "@app/features/auth/auth";
import { BANNERS_FEATURE_KEY } from "@app/features/banners/banners";
import {
  cardImagesReducer,
  CARD_IMAGES_FEATURE_KEY,
} from "@app/features/card-images/card-images";
import { CARD_MASTERS_FEATURE_KEY } from "@app/features/card-masters/card-masters";
import { cardsReducer } from "@app/features/cards/cards";
import { PACKS_FEATURE_KEY, packsReducer } from "@app/features/packs/packs";
import {
  PAYMENT_HISTORIES_FEATURE_KEY,
  paymentHistoriesReducer,
} from "@app/features/payment-histories/payment-histories";
import { ROLES_FEATURE_KEY, rolesReducer } from "@app/features/roles/roles";
import {
  SETTINGS_FEATURE_KEY,
  settingsReducer,
} from "@app/features/settings/settings";
import { SHOPS_FEATURE_KEY, shopsReducer } from "@app/features/shops/shops";
import { USERS_FEATURE_KEY, usersReducer } from "@app/features/users/users";

import { bannersReducer } from "../features/banners/redux/banners.slice";
import { cardMastersReducer } from "../features/card-masters/redux/card-masters.slice";
import { CARDS_FEATURE_KEY } from "../features/cards/constants/cards.keys";
import { LABELS_FEATURE_KEY } from "../features/labels/constants/labels.keys";
import { labelsReducer } from "../features/labels/redux/labels.slice";

const rootReducer = combineReducers({
  [AUTH_FEATURE_KEY]: authReducer,
  [PACKS_FEATURE_KEY]: packsReducer,
  [SHOPS_FEATURE_KEY]: shopsReducer,
  [SETTINGS_FEATURE_KEY]: settingsReducer,
  [CARDS_FEATURE_KEY]: cardsReducer,
  [USERS_FEATURE_KEY]: usersReducer,
  [PAYMENT_HISTORIES_FEATURE_KEY]: paymentHistoriesReducer,
  [BANNERS_FEATURE_KEY]: bannersReducer,
  [CARD_IMAGES_FEATURE_KEY]: cardImagesReducer,
  [ROLES_FEATURE_KEY]: rolesReducer,
  [ADMINS_FEATURE_KEY]: adminsReducer,
  [LABELS_FEATURE_KEY]: labelsReducer,
  [CARD_MASTERS_FEATURE_KEY]: cardMastersReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
