import { memo } from "react";

import { InputNumber as InputNumberAntd, InputNumberProps } from "antd";

const InputIntegerNumber = ({ ...props }: InputNumberProps) => {
  const parseNumber = (value?: string) => {
    return value ? Math.floor(parseFloat(String(value))) : "";
  };

  return <InputNumberAntd {...props} parser={parseNumber} />;
};

export default memo(InputIntegerNumber);
