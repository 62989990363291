/* eslint-disable no-template-curly-in-string */
export enum ERROR_MESSAGES {
  UA_00 = "システムエラーが発生しました。申し訳ございませんが、時間をおいてお試しください。",
  UAL_01 = "会員登録はまだ完了していません。再度ご確認お願いいたします。",
  UAL_02 = "[認証に失敗しました。]のメッセージを表示する",
  UA_02 = "認証に失敗しました。",
  UA_24 = "%attribute%は、${min}文字から${max}文字にしてください。",
  UA_22 = "%attribute%には、${min}KBから${max}KBまでのサイズのファイルを指定してください。",
  UA_36 = "%attribute%は、有効なメールアドレス形式で指定してください。",
  UA_40 = "%attribute%は必須です。",
  UA_148 = "問題が発生しました。後でもう一度お試しください。",
  TIMEOUT_ERROR = "ただ今サーバーが混み合っております。\nしばらく時間を置いてから再度ご利用くださいますよう、お願いいたします",
  ADMIN_LIST = "の 存在していないメッセージを選択しないでください",
}
