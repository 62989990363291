import { Suspense } from "react";

import { RouterProvider } from "react-router-dom";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";

import { useAppSelector } from "./redux/store";
import { router } from "./routes/Router";

import "@app/helpers/dayjs/dayjs.helper";

const App = () => {
  const { loadingUser } = useAppSelector(state => ({
    loadingUser: state.auth.loading,
  }));

  window.addEventListener("error", e => {
    if (/Loading chunk [\d]+ failed/.test(e.message)) {
      window.location.reload();
    }
  });

  const loading = <LoadingSpinner />;

  return (
    <Suspense fallback={loading}>
      {loadingUser && <LoadingSpinner />}
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
