import { getColumnTableConvertNullable } from "@app/helpers/util.helper";

export const COLUMNS_TABLE_BANNERS = getColumnTableConvertNullable([
  {
    title: "バナー画像_PC",
    dataIndex: "pc_image_path",
  },
  {
    title: "バナー画像_SP",
    dataIndex: "sp_image_path",
  },
  {
    title: "バナーURL",
    dataIndex: "href_url",
  },
  {
    title: "表示順",
    dataIndex: "display_order",
    width: 120,
  },
  {
    title: "ステータス",
    dataIndex: "is_display",
    width: 120,
  },
  {
    title: "操作",
    dataIndex: "action",
    width: 170,
  },
]);

export const DISPLAY_STATUS: Record<number, string> = {
  0: "",
  1: "表示",
};
