import { memo } from "react";

import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Menu, Typography } from "antd";
import Sider from "antd/lib/layout/Sider";
import cx from "classnames";
import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";

import logo from "@app/assets/images/logo_white.png";
import { MENU_ITEMS } from "@app/constants/constants";
import { isNotGranted } from "@app/helpers/permisstion.helper";
import { MenuItem } from "@app/types/menu.types";

import styles from "./SiderLayout.module.scss";

interface SiderLayoutProps {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

const SiderLayout = ({ collapsed, setCollapsed }: SiderLayoutProps) => {
  const navigate = useNavigate();

  const MenuItemCustom = ({ menuItem }: { menuItem: MenuItem }) => {
    const resolved = useResolvedPath(menuItem.pathName);
    const match = useMatch({ path: resolved.pathname, end: false });

    return (
      <Menu.Item
        key={menuItem.key}
        onClick={() => navigate(menuItem.pathName)}
        className={cx(
          match ? "ant-menu-item-selected" : "",
          isNotGranted(menuItem.code) ? "d-none" : ""
        )}
      >
        <Typography>{menuItem.label}</Typography>
      </Menu.Item>
    );
  };

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      className={cx(styles.sider)}
    >
      <div className={cx(styles.iconCollapse)}>
        {collapsed ? (
          <MenuUnfoldOutlined
            className="font-25 text-white"
            onClick={() => setCollapsed(!collapsed)}
          />
        ) : (
          <MenuFoldOutlined
            className="font-25 text-white"
            onClick={() => setCollapsed(!collapsed)}
          />
        )}
      </div>

      <div
        className={cx(styles.logo)}
        onClick={() => navigate("/")}
        aria-hidden
      >
        <img width="60%" src={logo} alt="" />
      </div>

      {!collapsed && (
        <Menu selectedKeys={[]} theme="dark" mode="inline">
          {MENU_ITEMS.map(item => (
            <MenuItemCustom key={item.key} menuItem={item} />
          ))}
        </Menu>
      )}
    </Sider>
  );
};

export default memo(SiderLayout);
