import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  LoginRequestBody,
  AUTH_FEATURE_KEY,
  AuthState,
  AdminParams,
  AuthUserDataDef,
  ResetPasswordDataDef,
} from "@app/features/auth/auth";
import { createApiAsyncThunk } from "@app/redux/api.thunk";

import authApi from "../api/auth.api";
import { authErrorHelper } from "../helpers/auth.helpers";

const initialState: AuthState = {
  tokenInfo: null,
  currentUser: null,
  loading: false,
  myPageInfo: null,
};

export const login = createApiAsyncThunk(
  `${AUTH_FEATURE_KEY}/login`,
  (data: LoginRequestBody) => authApi.authLoginApi(data)
);

export const postRefreshToken = createApiAsyncThunk(
  `${AUTH_FEATURE_KEY}/postRefreshToken`,
  (data: LoginRequestBody) => authApi.authLoginApi(data)
);

export const logout = createApiAsyncThunk(`${AUTH_FEATURE_KEY}/logout`, () =>
  authApi.authLogoutApi()
);

export const getCurrentUser = createApiAsyncThunk(
  `${AUTH_FEATURE_KEY}/getCurrentUser`,
  () => authApi.getCurrentUserApi()
);

export const getInformationUser = createApiAsyncThunk(
  `${AUTH_FEATURE_KEY}/getInformationUser`,
  () => authApi.getInformationUserApi()
);

export const updateInformationUser = createApiAsyncThunk(
  `${AUTH_FEATURE_KEY}/updateInformationUser`,
  async (data: AdminParams) => authApi.updateInformationUserApi(data)
);

export const checkTokenResetPassword = createApiAsyncThunk(
  `${AUTH_FEATURE_KEY}/checkTokenResetPassword`,
  (token: string) => authApi.checkTokenResetPasswordApi(token)
);

export const forgotPassword = createApiAsyncThunk(
  `${AUTH_FEATURE_KEY}/forgotPassword`,
  (data: AuthUserDataDef) => authApi.forgotPasswordApi(data)
);

export const resetPassword = createApiAsyncThunk(
  `${AUTH_FEATURE_KEY}/resetPassword`,
  (data: ResetPasswordDataDef) => authApi.resetPasswordApi(data)
);

const authSlice = createSlice({
  name: AUTH_FEATURE_KEY,
  initialState,
  reducers: {
    clearUser(state) {
      authErrorHelper(state);
    },
  },
  extraReducers: builder => {
    /**
     * LOGIN
     */
    builder.addCase(login.pending, state => {
      state.loading = true;
      state.tokenInfo = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.tokenInfo = action.payload;
    });
    builder.addCase(login.rejected, state => {
      authErrorHelper(state);
    });
    /**
     * Refresh token
     */
    builder.addCase(postRefreshToken.fulfilled, (state, action) => {
      state.tokenInfo = action.payload;
    });
    builder.addCase(postRefreshToken.rejected, state => {
      authErrorHelper(state);
    });
    /**
     * LOGOUT
     */
    builder.addCase(logout.pending, state => {
      state.loading = true;
    });
    builder.addCase(logout.fulfilled, state => {
      authErrorHelper(state);
    });
    builder.addCase(logout.rejected, state => {
      state.loading = false;
    });
    /**
     * CURRENT_USER
     */
    builder.addCase(getCurrentUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
    });
    builder.addCase(getCurrentUser.rejected, state => {
      authErrorHelper(state);
    });
    builder.addCase(getInformationUser.pending, state => {
      state.myPageInfo = null;
    });
    builder.addCase(getInformationUser.fulfilled, (state, action) => {
      state.myPageInfo = action.payload;
    });
  },
});

const authConfig = {
  key: AUTH_FEATURE_KEY,
  storage,
  whitelist: ["tokenInfo", "currentUser"],
};

export const LOCAL_STORAGE_AUTH_KEY = "persist:auth";

export const authReducer = persistReducer<AuthState>(
  authConfig,
  authSlice.reducer
);

export const { clearUser } = authSlice.actions;
