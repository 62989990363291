import { memo, useEffect, useMemo, useState } from "react";

import { Col, Row, Table, Typography, Input } from "antd";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import PaginationCustom from "@app/components/atoms/PaginationCustom/PaginationCustom";
import { LIST_CODE_SCREEN } from "@app/constants/constants";
import { CardPathsEnum } from "@app/features/cards/cards";
import { isNotGranted } from "@app/helpers/permisstion.helper";
import { handleSendMailto } from "@app/helpers/util.helper";
import useGranted from "@app/hooks/useGranted/useGranted";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import { COLUMNS_TABLE_USERS, UserPathEnum, getUserList } from "../../users";

const ListUserScreen = () => {
  const dispatch = useAppDispatch();
  const [search] = useSearchParams();
  const { userList } = useAppSelector((state: RootState) => state.users);
  const navigate = useNavigate();
  useGranted({ screenCode: LIST_CODE_SCREEN.USER_LIST });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [form] = useForm();

  const page = Number(search.get("page") ?? 1);
  const defaultUsername = search.get("username");
  const defaultEmail = search.get("email");
  const defaultTel = search.get("tel");
  const defaultShipTel = search.get("ship_tel");

  const handleSubmit = () => {
    const { username, email, tel, ship_tel } = form.getFieldsValue();
    const params = {
      username: username || undefined,
      email: email || undefined,
      tel: tel || undefined,
      ship_tel: ship_tel || undefined,
    };

    navigate({
      pathname: UserPathEnum.LIST_USER,
      search: `?${createSearchParams(JSON.parse(JSON.stringify(params)))}`,
    });
  };

  useEffect(() => {
    const params = {
      username: defaultUsername,
      email: defaultEmail,
      tel: defaultTel,
      ship_tel: defaultShipTel,
    };

    setIsLoading(true);
    dispatch(getUserList({ ...params, page, use_page: "true", active: "true" }))
      .unwrap()
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    defaultEmail,
    defaultShipTel,
    defaultTel,
    defaultUsername,
    dispatch,
    page,
  ]);

  const handleRedirectToCardList = (id: string) => {
    navigate(CardPathsEnum.LIST_CARD.replace(":userId", id));
  };

  const handleRedirectToDetailPage = (userId: string) => {
    return navigate(UserPathEnum.USER_DETAIL.replace(":userId", userId));
  };

  const usersDataSource = useMemo(() => {
    return userList?.users.map(item => ({
      key: item.id,
      ...item,
      action: (
        <div className="flex-align-center">
          <Button
            disabled={isNotGranted(LIST_CODE_SCREEN.USER_BUTTON_SEND_MAIL)}
            onClick={() => handleSendMailto(item.email)}
          >
            メール送信
          </Button>
          <Button
            className="ml-3"
            type="primary"
            disabled={isNotGranted(LIST_CODE_SCREEN.USER_DETAIL)}
            onClick={() => handleRedirectToDetailPage(String(item.id))}
          >
            ユーザーの詳細
          </Button>
          <Button
            onClick={() => handleRedirectToCardList(`${item.id}`)}
            disabled={isNotGranted(LIST_CODE_SCREEN.CARD_OF_USER_LIST)}
            className="ml-3"
            type="primary"
          >
            カード獲得一覧
          </Button>
        </div>
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList?.users]);

  return (
    <>
      <Typography.Title level={3}>ユーザーの一覧</Typography.Title>

      <Form form={form} onFinish={handleSubmit}>
        <Row gutter={12}>
          <Col span={6}>
            <Item
              name="username"
              label="ユーザー名"
              initialValue={defaultUsername}
            >
              <Input size="large" />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="email"
              label="メールアドレス"
              initialValue={defaultEmail}
            >
              <Input size="large" />
            </Item>
          </Col>
          <Col span={6}>
            <Item name="tel" label="電話" initialValue={defaultTel}>
              <Input size="large" />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="ship_tel"
              label="発送の電話番号"
              initialValue={defaultShipTel}
            >
              <Input size="large" />
            </Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={6}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              htmlType="submit"
              disabled={isLoading}
            >
              検索
            </Button>
          </Col>
        </Row>
      </Form>

      <Table
        className="mt-5"
        bordered
        pagination={false}
        scroll={{ x: 1000 }}
        loading={isLoading}
        dataSource={usersDataSource}
        columns={COLUMNS_TABLE_USERS}
      />
      <div className="mt-8 pb-5 text-center">
        <PaginationCustom
          current={page}
          total={userList?.page_info?.total_count}
        />
      </div>
    </>
  );
};

export default memo(ListUserScreen);
