import { memo, useState } from "react";

import { Col, Input, Row } from "antd";
import { serialize } from "object-to-formdata";
import { useNavigate } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import FormUpload from "@app/components/atoms/FormUpload/FormUpload";
import { setFieldErrors } from "@app/helpers/util.helper";
import { requireRules } from "@app/helpers/validations.helper";

import { Label, LabelsPathsEnum } from "../labels";

interface FormDataLabelProps {
  onSubmit: (data: FormData) => Promise<void>;
  labelDetail?: Label;
}

const FormDataLabel = ({ onSubmit, labelDetail }: FormDataLabelProps) => {
  const [form] = useForm();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = () => {
    setIsSubmitting(true);
    const formValues = form.getFieldsValue();

    onSubmit(serialize(formValues))
      .then(() => navigate(LabelsPathsEnum.LIST_LABEL))
      .catch(err => setFieldErrors({ err, form }))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Form onFinish={handleSubmit} form={form} initialValues={labelDetail}>
      <Item
        className="mx-auto mw-600"
        name="name"
        label="ラベル名称"
        rules={requireRules()}
      >
        <Input maxLength={255} />
      </Item>

      <Row className="mx-auto mw-600">
        <Col span={12}>
          <FormUpload
            rules={labelDetail?.image_url ? undefined : requireRules()}
            form={form}
            isUploadImage
            name="image"
            label="ラベル画像"
            className="mr-2"
            showRemoveIcon={!labelDetail}
            required
          />
        </Col>
      </Row>

      <Row className="mx-auto mw-600">
        <Col span={12}>
          <Row justify="end" className="mr-2">
            <Button
              className="full-width mw-200"
              onClick={() => navigate(LabelsPathsEnum.LIST_LABEL)}
            >
              キャンセル
            </Button>
          </Row>
        </Col>

        <Col span={12}>
          <Row justify="start" className="ml-2">
            <Button
              className="full-width mw-200"
              loading={isSubmitting}
              htmlType="submit"
              type="primary"
            >
              保存
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default memo(FormDataLabel);
