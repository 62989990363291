import { api } from "@app/api/api";
import { DEFAULT_PER_PAGE } from "@app/constants/constants";
import { RequestParams } from "@app/types/api.types";

import { BannersEndpointsEnum } from "../constants/banners.endpoints";

const bannerListApi = (params?: RequestParams) => {
  const url = BannersEndpointsEnum.LIST_BANNER;
  return api.get(url, { params: { ...params, limit: DEFAULT_PER_PAGE } });
};

const deleteBannerApi = ({ id }: { id: number }) => {
  const url = BannersEndpointsEnum.DELETE_BANNER.replace(":id", `${id}`);
  return api.delete(url);
};

const createBannerApi = (data: FormData) => {
  const url = BannersEndpointsEnum.CREATE_BANNER;
  return api.post(url, data, {
    transformRequest: () => {
      return data;
    },
  });
};

const editBannerApi = ({
  bannerId,
  data,
}: {
  bannerId: string;
  data: FormData;
}) => {
  const url = BannersEndpointsEnum.EDIT_BANNER;
  return api.post(url.replace(":id", bannerId), data, {
    params: {
      _method: "PATCH",
    },
    transformRequest: () => {
      return data;
    },
  });
};

const bannerApi = {
  bannerListApi,
  deleteBannerApi,
  createBannerApi,
  editBannerApi,
};

export default bannerApi;
